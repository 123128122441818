/*
 * DO NOT EDIT THIS FILE
 *
 * This file has been automatically generated and any changes
 * made here will NOT be preserved
 *
 * This file was generated from: /Users/antonyjc/Development/clients/kaialpha-poc/src/kaialpha/lib/error_utils.js
 *
 * DO NOT EDIT THIS FILE
 */
// eslint-disable-next-line
import kaialpha from '../kaialpha';
const retry = require('@lifeomic/attempt').retry;
const _testing = undefined;

class UserError extends Error {
	constructor(message) {
		super(message);
		this.name = "UserError";
	}
}

class ResourceNotFoundError extends Error {
	static CODE = 'RESOURCE_NOT_FOUND_ERROR'
	constructor(message) {
		super(message);
		this.name = "ResourceNotFoundError";
		this.code = ResourceNotFoundError.CODE;
	}
}

class TypeMismatchError extends Error {
	constructor(message) {
		super(message);
		this.name = "TypeMismatchError";
	}
}

class VersionMergeError extends Error {
	static CODE = "VERSION_MERGE_ERROR"
	constructor(message) {
		super(message);
		this.name = "VersionMergeError";
		this.code = VersionMergeError.CODE;
	}
}

class NotLatestVersionError extends Error {
	static CODE = "NOT_LATEST_VERSION_ERROR"
	constructor(message) {
		super(message);
		this.name = "NotLatestVersionError";
		this.code = NotLatestVersionError.CODE;
	}
}

class NunjucksError extends Error {
	constructor(last_element_info, render_error) {
		/*
		 * Handle instantiation from a string
		 */
		if ((last_element_info instanceof String || typeof last_element_info === 'string') && render_error === undefined) {
			const serialized_error = JSON.parse(String(last_element_info));

			if (serialized_error.nunjucks_error === true) {
				last_element_info = serialized_error.error_info;
				render_error = serialized_error.render_error
			}
		}

		let render_error_string = '';
		if (render_error) {
			render_error_string = render_error.toString();
		}

		const message = {
			error_info: last_element_info,
			render_error: render_error_string,
			nunjucks_error: true
		};

		super(JSON.stringify(message));

		this.name = 'NunjucksError';
	}
}

/**
 * Retry a function using exponential backoff strategy.
 * @template T
 * @param {() => PromiseLike<T> | T} run - Function that will be executed and retried on error.
 * @param {Object} [options] - Options
 * @param {(error: Error & {code?: string}) => PromiseLike<boolean> | boolean} [options.shouldRetryOnError] - Function to check if an error should be retried.
 * @param {number} [options.maxAttempts] - Maximum number of retries to attempt
 * @param {number} [options.delay] - Delay between each retry
 * @returns {Promise<T>}
 */
async function retry_with_backoff(run, options = {}) {

	const attemptFunction = async () => { return await run(); };

	const handleError = async (error, context) => {
		const { shouldRetryOnError } = options;
		if (!shouldRetryOnError || typeof shouldRetryOnError !== 'function') {
			return;
		}

		const retryable = await shouldRetryOnError(error);
		if (retryable === true) {
			return;
		}

		// if here, this is a non recoverable error, abort retrying.
		context.abort();
	};

	const {
		maxAttempts = 10,
		delay = 1000, // 1 second
	} = options;
	const result = await retry(attemptFunction, {
		factor: 2,
		jitter: true, // introduce randomness
		timeout: 0, // no upper bound on total time
		delay,
		maxAttempts,
		handleError,
	});
	return result;
}

if (_testing) {
	_testing.retry_with_backoff = async function () {
		let attempt = 0;
		let result;
		const run = () => {
			if (attempt <= 1) {
				attempt++;
				throw new UserError("dummy");
			}

			if (attempt === 2) {
				attempt++;
				throw new ResourceNotFoundError("dummy");
			}

			attempt = 0;
			return "success";
		};

		// test every error is retried
		try {
			result = await retry_with_backoff(run, {
				maxAttempts: 4,
				delay: 10,
			});
		} catch (error) {
			kaialpha.lib.testing_utils.assert(false, 'Retry threw unexpected error, expected no error', error.toString());
		}
		kaialpha.lib.testing_utils.assert(result === 'success', 'Expected retry function to return "success".');

		// test abort on unrecoverable errors
		try {
			result = undefined;
			result = await retry_with_backoff(run, {
				maxAttempts: 3,
				delay: 10,
				shouldRetryOnError: (error) => {
					if (error instanceof ResourceNotFoundError) {
						return false;
					}
					return true;
				}
			});
			kaialpha.lib.testing_utils.assert(result === undefined, 'Retry function should not return any result for unrecoverable error.');
		} catch (error) {
			kaialpha.lib.testing_utils.assert(error instanceof ResourceNotFoundError, 'Retry threw unexpected error, expected: ResourceNotFoundError', error);
		}

		return true;
	};
}

const _to_export_auto = {
	UserError,
	NunjucksError,
	ResourceNotFoundError,
	TypeMismatchError,
	VersionMergeError,
	NotLatestVersionError,
	retry_with_backoff,
	_testing,
}
export default _to_export_auto;
