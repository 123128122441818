/*
 * DO NOT EDIT THIS FILE
 *
 * This file has been automatically generated and any changes
 * made here will NOT be preserved
 *
 * This file was generated from: /Users/antonyjc/Development/clients/kaialpha-poc/src/kaialpha/lib/list_utils.js
 *
 * DO NOT EDIT THIS FILE
 */
// eslint-disable-next-line
import kaialpha from '../kaialpha';
const _testing = undefined;

const known_lists = ['abbreviation', 'citation'];

async function get_user_list_entries(user_id, list_type, list_id, list_version) {
	let list;
	try {
		list = await kaialpha.lib.list.get_user_list(user_id, list_type, list_id, list_version);
	} catch (ignored_error) {
		/*
		 * XXX:TODO: Distinguish between the error of list not existing (for which
		 * we can return an empty list of entries) and the error of fetching the
		 * list in which case we want to pass the error up to our caller.
		 */
		list = undefined;
	}

	if (list === undefined) {
		return([]);
	}

	let retval = list.entries;
	if (retval === undefined) {
		retval = [];
	}

	return(retval);
}

async function get_user_default_list(user_id, list_type, options = {}) {
	options = {
		...options,
		filter: ['default===true']
	}

	const lists_info = await kaialpha.lib.list.get_user_lists(user_id, list_type, options);
	const lists = lists_info[list_type];

	if (lists.length !== 1) {
		return(undefined);
	}

	return(lists[0]);
}

const _to_export_auto = {
	known_lists,
	get_user_list_entries,
	get_user_default_list,
	_testing
}
export default _to_export_auto;
