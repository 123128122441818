/*
 * DO NOT EDIT THIS FILE
 *
 * This file has been automatically generated and any changes
 * made here will NOT be preserved
 *
 * This file was generated from: /Users/antonyjc/Development/clients/kaialpha-poc/src/kaialpha/lib/audit_utils.js
 *
 * DO NOT EDIT THIS FILE
 */
// eslint-disable-next-line
import kaialpha from '../kaialpha';
/* XXX:TODO: Just rewrite all of this */

const csv_stringify = require('csv-stringify/lib/sync');
const he = require('he');
const title_case = require('title-case');

const _testing = undefined;

function _title_case(word) {
	switch (word) {
		case 'html':
			return('HTML');
		case 'id':
			return('ID');
		case 'acl':
			return('ACL');
		default:
			return(title_case.titleCase(word));
	}
}

function _now() {
	return(kaialpha.lib.date_utils.format_date(new Date(), {
		use_relative: false
	}));
}

function _get_time(date) {
	const date_milliseconds = new Date(date).getTime();
	return date_milliseconds;
}

function get_versions_within_date_limit(versions, start_date, end_date) {
	let versions_between_dates = [];
	if (start_date && end_date && start_date !== end_date) {
		const from_date_in_milliseconds = _get_time(start_date);
		const to_date_in_milliseconds = _get_time(end_date);
		for (const version of versions) {
			const current_date = _get_time(version.date);
			if (current_date >= from_date_in_milliseconds && current_date <= to_date_in_milliseconds) {
				versions_between_dates.push(version);
			}
		}
	} else {
		versions_between_dates = versions;
	}

	versions_between_dates.sort(function(version1, version2) {
		return(kaialpha.lib.date_utils.sort_dates_compare(version1, version2, 'asc', 'date'));
	});

	return versions_between_dates;
}

function _new_audit_log_event(section, event, options = {}) {
	if (section !== undefined && typeof(section) !== 'string') {
		throw(new Error(`May only add strings as section to audit entries, tried to add ${JSON.stringify(section)}`));
	}

	if (event !== undefined && typeof(event) !== 'string') {
		throw(new Error(`May only add strings as events to audit entries, tried to add ${JSON.stringify(event)}`));
	}

	for (const key of ['event', 'section']) {
		if (Object.keys(options).includes(key)) {
			throw(new Error(`May not supply a new "${key}" key through options: ${JSON.stringify(options)}`));
		}
	}

	const add_options = {};
	if (options.timestamp_date !== undefined && options.timestamp === undefined) {
		const timestamp_str = kaialpha.lib.date_utils.format_date(options.timestamp_date, { use_relative: false });
		add_options['timestamp'] = timestamp_str
	}

	const retval = {
		section: section,
		event: event,
		...add_options,
		...options
	};

	return(retval);
}

/*
 * To generate a diff of permissions, we really need to canonicalize the whole permissions document
 * so we need to fetch the whole thing at both times
 */
async function _get_audit_logs_diff_permissions(item_id, version_id_old, version_id_new, options = {}) {
	const audit_logs = [];

	let old_item;
	if (version_id_old === undefined) {
		old_item = {
			permissions: {}
		};
	} else {
		try {
			old_item = await options.get_item(options.user_id, item_id, version_id_old);
		} catch (fetch_item_error) {
			throw(new Error(`When fetching permissions of ${options.type} ID ${item_id}/${version_id_old}: ${JSON.stringify(fetch_item_error.message)}`));
		}
	}

	let new_item;
	try {
		new_item = await options.get_item(options.user_id, item_id, version_id_new);
	} catch (fetch_item_error) {
		throw(new Error(`When fetching permissions of ${options.type} ID ${item_id}/${version_id_new}: ${JSON.stringify(fetch_item_error.message)}`));
	}

	const klvcp_options = {
		get_item_any_type: async function() {
			/* XXX:TODO: How should we handle logging changes to inherited permissions ? */
			return({});
		}
	};
	const old_permissions = await kaialpha.lib.versions_utils.canonicalize_permissions(old_item.permissions, klvcp_options);
	const new_permissions = await kaialpha.lib.versions_utils.canonicalize_permissions(new_item.permissions, klvcp_options);

	const diff = kaialpha.lib.diff_utils.diff_objects(old_permissions, new_permissions);

	await kaialpha.lib.recursive_object_utils.exec(diff, async function(object, path, key) {
		const modification_type = path[0];
		const changed_part = [...path, key].slice(1);

		const event_info = object[key];
		const old_value = event_info[0];
		const new_value = event_info[1];

		let extra_info = '';
		if (changed_part.length > 1) {
			extra_info = `: ${changed_part[1]}`;
		}

		const permissions_diff = kaialpha.lib.diff_utils.diff_array(old_value, new_value);

		for (const permissions_diff_change_type of Object.keys(permissions_diff)) {
			for (const permissions_user_id of permissions_diff[permissions_diff_change_type]) {
				const permissions_user_name = await options.get_user_displayname(permissions_user_id);
				const permissions_change = `: ${_title_case(permissions_diff_change_type)} ${permissions_user_name}`;
				const event_name = `${_title_case(modification_type)} ${_title_case(options.type)} ${_title_case(changed_part[0])}${extra_info}${permissions_change}`;

				audit_logs.push(_new_audit_log_event('[Permissions]', event_name, options.event_options));
			}
		}
	});

	return(audit_logs);

}

/*
 * To generate a diff of comments, we need to fetch the whole document to identify the element being commented on
 */
async function _get_audit_logs_diff_comments(item_id, version_id_old, version_id_new, options = {}) {
	return({item_id, version_id_old, version_id_new, options});
}

async function get_audit_logs_from_versions(versions, item_id, options = {}) {
	options = {
		type: undefined,
		item_name: undefined,
		parent_name: undefined,
		get_user_displayname: kaialpha.lib.user.get_user_displayname,
		get_diff_by_version_ids: undefined,
		get_item: undefined,
		...options
	};

	/*
	 * Validate arguments
	 */
	if (!item_id) {
		throw(new Error('Invalid item ID supplied'));
	}

	switch (options.type) {
		case 'document':
		case 'template':
			break;
		default:
			throw(new Error(`Unsupported type: ${options.type}`));
	}

	if (options.get_diff_by_version_ids === undefined) {
		switch (options.type) {
			case 'document':
				options.get_diff_by_version_ids = kaialpha.lib.document.diff_user_document;
				break;
			case 'template':
				options.get_diff_by_version_ids = kaialpha.lib.template.diff_user_template;
				break;
			default:
				/* Ignored, we checked earlier */
				break;
		}
	}

	if (options.get_item === undefined) {
		switch (options.type) {
			case 'document':
				options.get_item = kaialpha.lib.document.get_user_document;
				break;
			case 'template':
				options.get_item = kaialpha.lib.template.get_user_template;
				break;
			default:
				/* Ignored, we checked earlier */
				break;
		}
	}

	/*
	 * Process each version and generate an event for each changed portion
	 */
	const audit_logs = [];

	if (!versions || !Array.isArray(versions) || versions.length === 0) {
		return(audit_logs);
	}

	let previous_version_id;
	for (const version_info of versions) {
		const current_version_id = version_info.version;

		let diff = undefined;
		if (previous_version_id) {
			diff = await options.get_diff_by_version_ids(options.user_id, item_id, previous_version_id, current_version_id);
		} else {
			const current_item = await options.get_item(options.user_id, item_id, current_version_id);
			diff = kaialpha.lib.diff_utils.diff_objects({}, current_item);
		}

		const current_user_name = await options.get_user_displayname(version_info.author);

		const timestamp = new Date(version_info.date);

		const event_options = {
			id: item_id,
			version: current_version_id,
			user: current_user_name,
			timestamp_date: timestamp,
			item_name: options.item_name
		};

		let changed_permissions = false;
		let changed_comments = false;
		const dedupe_vars = {};
		await kaialpha.lib.recursive_object_utils.exec(diff, async function(object, path, key) {
			/*
			 * Do not generate audit events for changing version
			 * information since it's not really a change done
			 * by the user, and they cannot stop it from
			 * happening.
			 */
			if (['id', 'version', 'previous_version'].includes(key)) {
				return;
			}

			const modification_type = path[0];
			const changed_part = [...path, key].slice(1);

			const event_info = object[key];
			const old_value = event_info[0];
			const new_value = event_info[1];
			let extra_info = '';

			if (changed_part[0] === 'permissions') {
				changed_permissions = true;
				return;
			}

			if (changed_part[0] === 'comments') {
				/* XXX:TODO: Improve comment output */
				/**
				 ** changed_comments = true;
				 ** return;
				 **/
				changed_comments = false;
				if (changed_part.length > 3) {
					extra_info = `: On ${changed_part[1].replace(/s$/, "")} ID ${changed_part[2]}, comment field: ${changed_part.slice(3).join(' ')}`;
				} else {
					extra_info = `: On ${changed_part[1].replace(/s$/, "")} ID ${changed_part[2]}`;
				}
			}

			if (extra_info === '') {
				if (changed_part.length > 1) {
					extra_info = `: ${changed_part[1]}`;
				}
			}

			/*
			 * For arrays, we can be more descriptive of the change
			 */
			if (modification_type === 'changed' && Array.isArray(new_value)) {
				if ((key === 'body' && options.type === 'template') || (key === 'body_extend' && options.type === 'document')) {
					const old_value_body = kaialpha.lib.document_utils.body_by_element_tag(null, old_value);
					const new_value_body = kaialpha.lib.document_utils.body_by_element_tag(null, new_value);
					const array_diff = kaialpha.lib.diff_utils.diff_array(old_value_body, new_value_body, {
						compare_function: function(a, b) {
							const a_copy = {
								...a,
								contents: {
									...a.contents,
									body: undefined
								}
							};
							const b_copy = {
								...b,
								contents: {
									...b.contents,
									body: undefined
								}
							};
							if (kaialpha.lib.object_utils.object_equals(a_copy, b_copy)) {
								return(0);
							}
							return(1);
						}
					});

					for (const body_mod_type of ['added', 'changed', 'deleted']) {
						const diff_part = array_diff[body_mod_type];
						if (!diff_part) {
							continue;
						}

						for (const body_element of diff_part) {
							const diff_body = body_element;

							const event_name = `Changed ${key}: ${_title_case(body_mod_type)} body element ${_title_case(diff_body.type)}`;

							audit_logs.push(_new_audit_log_event('', event_name, event_options));
						}
					}

					return;
				} else {
					/* XXX:TODO: For other types of arrays, indicate the exact change */
				}
			}

			const event_name = `${_title_case(modification_type)} ${_title_case(options.type)} ${_title_case(changed_part[0])}${extra_info}`;

			/* XXX: Crude de-dupe mechanism, implement better one */
			if (dedupe_vars[event_name]) {
				return;
			}
			dedupe_vars[event_name] = true;

			audit_logs.push(_new_audit_log_event('', event_name, event_options));
		});

		if (changed_permissions) {
			audit_logs.push(...(await _get_audit_logs_diff_permissions(item_id, previous_version_id, current_version_id, {
				...options,
				event_options
			})));
		}

		if (changed_comments) {
			audit_logs.push(await _get_audit_logs_diff_comments(item_id, previous_version_id, current_version_id, {
				...options,
				event_options
			}));
		}

		previous_version_id = current_version_id;
	}

	return(audit_logs);
}

if (_testing) {
	const test_templates = {
		'390c645f-ed15-45a0-b403-7ce215b86ada': {
			'c5ede95a-b2eb-4a8d-a803-a2ca22e01e03': {
				name: 'Test Template 1'
			},
			'db583948-77d3-400a-8229-92681180aa44': {
				name: 'Test Template 1 (Top)',
				body: [
					{'78235d62-8c7e-41ed-a94e-31e23ddd065d': {
						type: 'title',
						title: 'Testing !'
					}},
					{'74a8c091-b71e-4b48-8233-62f42ffd3354': {
						type: 'variable',
						name: 'TestVar'
					}}
				],
				metadata: {
					'Key1': 'Value1-1',
					'Key3': 'Value3'
				},
				variables: {
					'TestVar': {
						type: 'text'
					}
				}
			},
			'23eda8d7-0c53-4cac-abf9-6387afaad09a': {
				name: 'Test Template 1 (Top)',
				body: [
					{'78235d62-8c7e-41ed-a94e-31e23ddd065d': {
						type: 'title',
						title: 'Testing !'
					}},
					{'74a8c091-b71e-4b48-8233-62f42ffd3354': {
						type: 'variable',
						name: 'TestVar'
					}},
					{'ad898e72-f921-489b-bad0-20d22f06fafc': {
						type: 'section',
						name: 'Section 1',
						body: [
							{'3ba550d0-59e7-4922-af1c-6f3ed0cc3011': {
								type: 'template',
								name: 'SubTempl1',
								id: 'bbd6913e-e547-4902-b52a-7ef1b6a92547',
								version: 'HEAD'
							}}
						]
					}}
				],
				metadata: {
					'Key1': 'Value1',
					'Key2': 'Value2'
				},
				variables: {
					'TestVar': {
						type: 'text'
					}
				}
			},
			'707b9e78-3c53-4605-acae-48b83b7e3576': {
				name: 'Test Template 1 (Top)',
				body: [
					{'78235d62-8c7e-41ed-a94e-31e23ddd065d': {
						type: 'title',
						title: 'Testing !'
					}},
					{'74a8c091-b71e-4b48-8233-62f42ffd3354': {
						type: 'variable',
						name: 'TestVar'
					}},
					{'ad898e72-f921-489b-bad0-20d22f06fafc': {
						type: 'section',
						name: 'Section 1',
						body: [
							{'3ba550d0-59e7-4922-af1c-6f3ed0cc3011': {
								type: 'template',
								name: 'SubTempl1',
								id: 'bbd6913e-e547-4902-b52a-7ef1b6a92547',
								version: 'HEAD'
							}}
						]
					}}
				],
				metadata: {
					'Key1': 'Value1',
					'Key2': 'Value2'
				},
				variables: {
					'TestVar': {
						type: 'text'
					}
				}
			},
			'30afc252-93ec-42af-8250-64105e0b1bae': {
				name: 'Test Template 1 (Top)',
				body: [
					{'78235d62-8c7e-41ed-a94e-31e23ddd065d': {
						type: 'title',
						title: 'Testing !'
					}},
					{'74a8c091-b71e-4b48-8233-62f42ffd3354': {
						type: 'variable',
						name: 'TestVar'
					}},
					{'ad898e72-f921-489b-bad0-20d22f06fafc': {
						type: 'section',
						name: 'Section 1',
						body: [
							{'3ba550d0-59e7-4922-af1c-6f3ed0cc3011': {
								type: 'template',
								name: 'SubTempl1',
								id: 'bbd6913e-e547-4902-b52a-7ef1b6a92547',
								version: 'HEAD'
							}}
						]
					}}
				],
				metadata: {
					'Key1': 'Value1',
					'Key2': 'Value2'
				},
				variables: {
					'TestVar': {
						type: 'text'
					}
				},
				comments: {
					"elements": {
						'ad898e72-f921-489b-bad0-20d22f06fafc': [{
							id: 'c0ff7cbd-68cd-4140-a1d4-a1a78b6be4c0',
							author: 'ad898e72-f921-489b-bad0-20d22f06fafc',
							date: '20210603T00000Z',
							state: 'IN REVIEW',
							comment: 'Test'
						}]
					}
				}
			},
			'0b454bcf-c464-489f-9539-9b851fdc424d': {
				name: 'Test Template 1 (Top)',
				body: [
					{'78235d62-8c7e-41ed-a94e-31e23ddd065d': {
						type: 'title',
						title: 'Testing !'
					}},
					{'74a8c091-b71e-4b48-8233-62f42ffd3354': {
						type: 'variable',
						name: 'TestVar'
					}},
					{'ad898e72-f921-489b-bad0-20d22f06fafc': {
						type: 'title',
						title: 'Random Title'
					}},
					{'ad898e72-f921-489b-bad0-20d22f06fafc': {
						type: 'section',
						name: 'Section 1',
						body: [
							{'3ba550d0-59e7-4922-af1c-6f3ed0cc3011': {
								type: 'template',
								name: 'SubTempl1',
								id: 'bbd6913e-e547-4902-b52a-7ef1b6a92547',
								version: 'HEAD'
							}},
							{'7e0a9144-9172-45af-b4d2-f9f2be2eb8e7': {
								type: 'html',
								text: 'Text'
							}}
						]
					}}
				],
				metadata: {
					'Key1': 'Value1',
					'Key2': 'Value2'
				},
				variables: {
					'TestVar': {
						type: 'text'
					}
				},
				comments: {
					"elements": {
						'ad898e72-f921-489b-bad0-20d22f06fafc': [{
							id: 'c0ff7cbd-68cd-4140-a1d4-a1a78b6be4c0',
							author: 'ad898e72-f921-489b-bad0-20d22f06fafc',
							date: '20210603T00000Z',
							state: 'RESOLVED',
							comment: 'Test'
						}]
					}
				}
			},
		},
		'bbd6913e-e547-4902-b52a-7ef1b6a92547': {
			'c3c37e10-ad3b-4a80-beea-1a2323d7300e': {
				name: 'Test Template 1 (Subtemplate)',
			},
			'443b048e-4835-4ff8-9a15-b25063f2df90': {
				name: 'Test Template 1 (Subtemplate)',
				body: [
					{'d27d48d1-5451-4ef1-b93d-4bd0143a24bc': {
						type: 'title',
						title: 'Subtemplate'
					}},
					{'9a93bf7d-fbf7-494f-9328-3339ee757282': {
						type: 'section',
						name: 'Section 1.1',
						body: [
							{'b77bc20e-719f-4bf1-b835-531fc0902198': {
								type: 'html',
								text: 'Contents of Section 1.1'
							}}
						]
					}}
				]
			}
		}
	};

	const test_documents = {
		'28cc83ba-d634-428f-b122-ec709c3e80ee': {
			'd0f2cf5d-9ae1-4b4b-b35f-2a9b8daee303': {
				name: 'Test Document 1',
				template: {
					id: '390c645f-ed15-45a0-b403-7ce215b86ada',
					version: 'c5ede95a-b2eb-4a8d-a803-a2ca22e01e03'
				}
			},
			'e87eacad-0605-48f1-8b71-7cbfdce615f8': {
				name: 'Test Document 1',
				template: {
					id: '390c645f-ed15-45a0-b403-7ce215b86ada',
					version: 'c5ede95a-b2eb-4a8d-a803-a2ca22e01e03'
				}
			},
			'550150c9-999e-481d-92f6-3f1afc14f979': {
				name: 'Test Document 1',
				template: {
					id: '390c645f-ed15-45a0-b403-7ce215b86ada',
					version: '23eda8d7-0c53-4cac-abf9-6387afaad09a'
				},
				subdocuments: {
					'3ba550d0-59e7-4922-af1c-6f3ed0cc3011': {document_id: ['696eb27a-1369-4557-8be5-abb82caa9353']}
				}
			},
			'2275e5bc-b671-43bb-a465-40abe244023f': {
				name: 'Test Document 1',
				template: {
					id: '390c645f-ed15-45a0-b403-7ce215b86ada',
					version: '23eda8d7-0c53-4cac-abf9-6387afaad09a'
				},
				variables: {
					'TestVar1': 'Value1'
				},
				subdocuments: {
					'3ba550d0-59e7-4922-af1c-6f3ed0cc3011': {document_id: ['696eb27a-1369-4557-8be5-abb82caa9353']}
				}
			}
		},
		'696eb27a-1369-4557-8be5-abb82caa9353': {
			'9e4f85f1-d5ae-46ab-887c-306165e9ab5d': {
				name: 'Test Document 1 (Subdocument)',
				template: {
					id: 'bbd6913e-e547-4902-b52a-7ef1b6a92547',
					version: 'c3c37e10-ad3b-4a80-beea-1a2323d7300e'
				}
			},
			'3716642e-14cd-4388-a495-95a75721c138': {
				name: 'Test Document 1 (Subdocument)',
				superdocument: {
					document_id: '28cc83ba-d634-428f-b122-ec709c3e80ee'
				},
				template: {
					id: 'bbd6913e-e547-4902-b52a-7ef1b6a92547',
					version: 'c3c37e10-ad3b-4a80-beea-1a2323d7300e'
				}
			}
		}
	};

	(function() {
		for (const template_id in test_templates) {
			let previous_version;
			for (const template_version in test_templates[template_id]) {
				const template = test_templates[template_id][template_version];
				template.id = template_id;
				template.version = template_version;
				template.previous_version = previous_version;
				previous_version = template_version;
				template.permissions = {
					owners: ['@system']
				};
			}
		}
		test_templates['bbd6913e-e547-4902-b52a-7ef1b6a92547']['443b048e-4835-4ff8-9a15-b25063f2df90'].permissions.owners.push('test-uid');
		test_templates['390c645f-ed15-45a0-b403-7ce215b86ada']['23eda8d7-0c53-4cac-abf9-6387afaad09a'].permissions = {
			owners: ['@system', 'test-uid'],
			roles: {
				'author': ['test-author']
			},
			acl: {
				'write': ['@role:author']
			}
		}

		for (const document_id in test_documents) {
			let previous_version;
			for (const document_version in test_documents[document_id]) {
				const document = test_documents[document_id][document_version];

				document.id = document_id;
				document.version = document_version;
				document.previous_version = previous_version;
				previous_version = document_version;
				document.permissions = {
					owners: ['@system']
				};
			}
		}
	})();

	const test_get_user_document = async function(user_id, document_id, document_version) {
		return(kaialpha.lib.object_utils.copy_object(test_documents[document_id][document_version]));
	}

	const test_get_user_template = async function(user_id, template_id, template_version) {
		return(kaialpha.lib.object_utils.copy_object(test_templates[template_id][template_version]));
	}

	const test_diff_user_document = async function(user_id, document_id, version_old, version_new) {
		const document_old = await test_get_user_document(user_id, document_id, version_old);
		const document_new = await test_get_user_document(user_id, document_id, version_new);
		return(kaialpha.lib.diff_utils.diff_objects(document_old, document_new));
	}

	const test_diff_user_template = async function(user_id, template_id, version_old, version_new) {
		const template_old = await test_get_user_template(user_id, template_id, version_old);
		const template_new = await test_get_user_template(user_id, template_id, version_new);
		return(kaialpha.lib.diff_utils.diff_objects(template_old, template_new));
	}

	const test_get_user_displayname = async function(user_id) {
		return(`${user_id}`);
	}

	_testing.get_audit_logs_from_versions_validate_items = async function() {
		/* XXX:TODO: This is a testing side-effect, fix it */
		kaialpha.lib.validation_utils.init({
			extra_validation: false
		});

		for (const template_id in test_templates) {
			for (const template_version in test_templates[template_id]) {
				const template = test_templates[template_id][template_version];

				const template_valid = await kaialpha.lib.validation_utils.validate_template('@system', template);
				if (template_valid.length !== 0) {
					throw(new Error(`Validating template ${template_id}/${template_version}: ${JSON.stringify(template_valid)}`));
				}
			}
		}

		for (const document_id in test_documents) {
			for (const document_version in test_documents[document_id]) {
				const document = test_documents[document_id][document_version];

				const document_valid = await kaialpha.lib.validation_utils.validate_document('@system', document);
				if (document_valid.length !== 0) {
					throw(new Error(`Validating document ${document_id}/${document_version}: ${JSON.stringify(document_valid)}`));
				}
			}
		}

		return(true);
	};

	_testing.get_audit_logs_from_versions_template = async function() {
		const template_id = '390c645f-ed15-45a0-b403-7ce215b86ada';
		let index = 0;
		const template_versions = Object.keys(test_templates[template_id]).map(function(version_id) {
			const hour = `00${index}`.slice(-2);
			index++;

			return({
				id: template_id,
				version: version_id,
				author: '@system',
				summary: null,
				date: `2021-05-25T${hour}:00:00.002Z`
			});
		});

		const events = await get_audit_logs_from_versions(template_versions, template_id, {
			type: 'template',
			item_name: 'Test Template 1',
			get_user_displayname: test_get_user_displayname,
			get_diff_by_version_ids: test_diff_user_template,
			get_item: test_get_user_template
		});

		/* XXX:TODO: Add test here */
		if (events.length !== 20) {
			throw(new Error(`Incorrect length event log generated.  Got ${events.length} expected 20`));
		}

		return(true);
	};

	_testing.get_audit_logs_from_versions_document = async function() {
		const document_id = '28cc83ba-d634-428f-b122-ec709c3e80ee';
		let index = 0;
		const document_versions = Object.keys(test_documents[document_id]).map(function(version_id) {
			const hour = `00${index}`.slice(-2);
			index++;

			return({
				id: document_id,
				version: version_id,
				author: '@system',
				summary: null,
				date: `2021-05-25T${hour}:00:00.002Z`
			});
		});

		/* const events = */await get_audit_logs_from_versions(document_versions, document_id, {
			type: 'document',
			item_name: 'Test Document 1',
			get_user_displayname: test_get_user_displayname,
			get_diff_by_version_ids: test_diff_user_document,
			get_item: test_get_user_document
		});

		/* XXX:TODO: Add test here */
		// console.log({events});

		return(true);
	};
}

function _html_encode(data) {
	if (data === undefined || data === null || data === '') {
		return('&nbsp;');
	}

	if (typeof(data) !== 'string') {
		throw(new Error(`Tried to write out Object ${JSON.stringify(data)} in place of string`));
	}

	return(he.encode(data));
}

async function _get_export_metadata(user_id, metadata) {
	const type_titlecase = _title_case(metadata.type);

	const user_name = await kaialpha.lib.user.get_user_username(user_id);
	const item_info = await kaialpha.lib.general_utils.get_item_any_type(user_id, metadata.type, metadata.id, 'HEAD', {
		fields: ['name']
	});
	const item_name = item_info.name;

	return({
		type_titlecase,
		user_name,
		item_name
	});
}

async function get_csv_file(user_id, logs, metadata) {
	const { type_titlecase, user_name, item_name } = await _get_export_metadata(user_id, metadata);

	const records = [];
	for (const log of logs) {
		if (!log) {
			continue;
		}

		records.push(log);
	}

	records.push({});
	records.push({
		templateName: `Audit Log for ${type_titlecase} named "${item_name}" (ID: ${metadata.id})`
	});
	records.push({
		templateName: `Generated on ${_now()} by ${user_name}`
	});

	const csv_records_str = csv_stringify(records, {
		columns: [
			{key: 'item_name', header: `${type_titlecase} Name`},
			{key: 'section', header: 'Section'},
			{key: 'event', header: 'Event'},
			{key: 'user', header: 'User'},
			{key: 'timestamp', header: 'Timestamp'}
		],
		header: true
	});

	const csv_uri = 'data:text/csv;charset=utf-8,' + Buffer.from(csv_records_str, 'utf-8');

	return(csv_uri);
}

async function get_pdf(user_id, logs, metadata) {
	const { type_titlecase, user_name, item_name } = await _get_export_metadata(user_id, metadata);

	const auditLog = [];
	auditLog.push(`
		<html>
		<head>
		<style>
		table {
			width: 100%,
			border-collapse: collapse;
		}
		td, th {
			border: 1px solid #ddd;
		}
		th {
			text-align: center;
			background-color: #0388fc;
			color: white;
		}
		</style>
		</head>
		<body>
		Audit Log for ${type_titlecase} named "${_html_encode(item_name)}" (ID: ${metadata.id})<br>
		Generated on ${_now()} by ${_html_encode(user_name)}
		<table>
	`);

	auditLog.push(`
			<tr>
				<th>${type_titlecase} Name</th>
				<th>Section</th>
				<th>Event</th>
				<th>User</th>
				<th>Timestamp</th>
			</tr>
	`)

	for (const log of logs) {
		if (!log) {
			continue;
		}

		let section = '';
		if (log.templateName) {
			const nameArr = [log.templateName, log.section];
			section = nameArr.join('\n');
		} else {
			section = log.section;
		}

		auditLog.push(`
				<tr>
					<td>${_html_encode(log.item_name)}</td>
					<td>${_html_encode(section)}</td>
					<td>${_html_encode(log.event)}</td>
					<td>${_html_encode(log.user)}</td>
					<td>${_html_encode(log.timestamp)}</td>
				</tr>
		`);
	}

	auditLog.push(`</table>
		</body>
		</html>
	`);

	const html = auditLog.join("\n");

	const pdf = await kaialpha.lib.template.generatePDFFromHTML(html);

	return pdf.link;
}

const _to_export_auto = {
	get_versions_within_date_limit: get_versions_within_date_limit,
	get_audit_logs_from_versions: get_audit_logs_from_versions,
	get_csv_file: get_csv_file,
	get_pdf: get_pdf,
	_testing
}
export default _to_export_auto;
