/*
 * DO NOT EDIT THIS FILE
 *
 * This file has been automatically generated and any changes
 * made here will NOT be preserved
 *
 * This file was generated from: /Users/antonyjc/Development/clients/kaialpha-poc/src/kaialpha/lib/tasks_utils.js
 *
 * DO NOT EDIT THIS FILE
 */
// eslint-disable-next-line
import kaialpha from '../kaialpha';
import cache_utils from './cache_utils';
// @ts-check-strict

async function clear_cache() {
	await cache_utils.clear('cached_tasks');
}
/**
 *  Returns formatted task list
 *  @param {Object} possible_tasks - list of all possible tasks
 *  @param {Partial<KaiAlphaCMSItem>[]} possible_tasks - documents or templates with tasks
 *  @param {Object} filter_options -  Optional parameter to apply filter on task list
 *  @returns {Promise<{ tasks: object[]}>} formatted task list
 */
async function task_list(possible_tasks, filter_options={}) {
	const tasks = [];

	for (const item of possible_tasks) {
		const task_source = {
			source: {
				type: item.type,
				id: item.id,
				version: item.version,
				name: item.name,
				state: item.state
			},
		};
		for (const slot_name in item.workflow) {
			let workflow_state;
			try {
				if (filter_options.user_id){
					workflow_state = await kaialpha.lib.workflow_utils.workflow_ui_filter_state(filter_options.user_id, item.workflow[slot_name], {
						permissions: item.permissions
					});
				} else {
					/*
					 * If we are getting all tasks for an item, we
					 * can pass in the System User ID (@system) to
					 * avoid having it filter out buttons
					 */
					workflow_state = await kaialpha.lib.workflow_utils.workflow_ui_filter_state('@system', item.workflow[slot_name], {
						permissions: item.permissions
					});

				}
			} catch (err) {
				console.error(`[ERROR] Unhandled exception while fetching tasks for %j`, task_source);
				continue;
			}
			//Get all closed tasks for document
			if (!filter_options.user_id){
				if (workflow_state.completed_tasks){
					workflow_state.completed_tasks.forEach(completed_task => {
						const result = completed_task.result;
						const status = result === 'Cancelled' ? 'Cancelled' : 'Closed';
						tasks.push({
							...task_source,
							operation: completed_task.action,
							result: result,
							who: [completed_task.who],
							status: status,
							comment: completed_task.comment,
						});
					});
				}
			}

			//Get all open tasks
			if (workflow_state.status !== 'waiting') {
				// if here, workflow is closed
				continue;
			}
			if (workflow_state.ui === undefined) {
				// if here, workflow is closed?
				continue;
			}

			const workflow_ui = workflow_state.ui;

			// get the users who can act on this task
			const collected_users_map = {};
			if (workflow_ui.buttons) {
				const new_buttons = [];
				for (const button of workflow_ui.buttons) {
					// skip buttons that are not shown on tasks page
					if (button.task === false) {
						continue;
					}

					/**
					 * Some edge case yet to be figured out, is causing acl to be undefined for some buttons.
					 * Probably if document review is cancelled or document acl is updated at some point after starting review.
					 * So, use default values when destructuring to avoid error if acl is undefined.
					 */
					const { permissions: { acl: { write: button_write_access = [] } = {} } = {} } = button;
					const button_users = button_write_access;
					for (const button_user of button_users) {
						collected_users_map[button_user] = true;
					}
					new_buttons.push(button);
				}
				workflow_ui.buttons = new_buttons;
			}

			if (!workflow_ui.prompts) {
				if (workflow_ui.buttons && workflow_ui.buttons.length === 0) {
					continue;
				}
			}
			if (workflow_state.timeout) {
				workflow_state.timeout.at = kaialpha.lib.date_utils.add_date(workflow_state.timeout.in, workflow_state.timeout.start);
			}

			const collected_users = Object.keys(collected_users_map);

			const task = {
				slot: slot_name,
				operation: workflow_state.operation,
				ui: workflow_ui,
				timeout: workflow_state.timeout,
				who: collected_users,
				status:  'Open'
			};

			if (filter_options.user_id) {
				tasks.push({
					...task_source,
					...task,
					status: get_user_pending_task_status(filter_options.user_id, workflow_state),
				});
			} else {
				collected_users.forEach( user => {
					tasks.push({
						...task_source,
						...task,
						who: [user],
						status: get_user_pending_task_status(user, workflow_state),
					});
				});
			}
		}
	}
	return({
		tasks
	});
}

/**
 * Helper to get the status of pending task of a user.
 * @param {string} user_id - ID of the user
 * @param {*} workflow_state - State of the workflow slot
 * @returns {"Open" | "In Process"}
 * - `In Process` = user is currently reviewing the document.
 * - `Open` = user hasn't started the review yet.
 */
function get_user_pending_task_status(user_id, workflow_state){
	const { variables: { tracking = {} } = {} } = workflow_state;
	const user_tracking = tracking[user_id] || [];
	// if a tracking entry exists then the user viewed the document during the review
	const status = user_tracking.length > 0 ? 'In Process' : 'Open';
	return status;
}

/**
 * Returns user's tasks
 * @param {string} user_id - user id
 * @returns {Promise<Object>} user's tasks
 */
async function get_user_tasks(user) {
	const user_id = user.id;

	const allFilters = [`@workflows_waiting=${user.id}`];
	if (user.groups) {
		for(const curGroup of user.groups) {
			allFilters.push(`@workflows_waiting=${curGroup}`);
		}
	}

	const cache_id = `user_tasks-${user_id}`;
	let possible_user_tasks = await cache_utils.cache_promise('cache_tasks', cache_id, async function() {
		const waiting_docs = kaialpha.lib.document.get_user_documents(user_id, {
			filter: allFilters,
			filter_minimum_should_match: 1,
			fields: ['id', 'version', 'name', 'state', 'workflow', 'permissions', '_cached_canonical_permissions', '@workflows_waiting', '_external_data'],
			auto_paginate: true,
			count: 100,
		});
		const waiting_templates = kaialpha.lib.template.get_user_templates(user_id, {
			filter: allFilters,
			filter_minimum_should_match: 1,
			fields: ['id', 'version', 'state', 'name', 'workflow', 'permissions', '_cached_canonical_permissions', '@workflows_waiting', '_external_data'],
			auto_paginate: true,
			count: 100,
		});
		const [doc_tasks, template_tasks] = await Promise.all([waiting_docs, waiting_templates]);
		const templates = template_tasks.templates.map(template => {
			return {
				...template,
				type: 'template',
			};
		});
		const documents = doc_tasks.documents.map(document => {
			return {
				...document,
				type: 'document',
			};
		});
		const res = [...templates, ...documents,];
		return res;
	}, {
		cache_expires: 2000,
	});

	possible_user_tasks = possible_user_tasks?.map(item => {
		const { _external_data: { cache } } = item;
		if (!cache) {
			console.error(`[ERROR] Skipped tasks check. Item _external_data.cache not found for %j`, item);
			return undefined;
		}
		const fullItem = JSON.parse(cache);
		fullItem.permissions = item._cached_canonical_permissions || item.permissions;
		fullItem['@workflows_waiting'] = item['@workflows_waiting'];
		fullItem.type = item.type;
		return fullItem;
	}).filter(x => !!x);

	const filter_by = { user_id };
	return await task_list(possible_user_tasks, filter_by);
}

/**
 * Returns document's and its subdocument's tasks
 * @param user_id - user id
 * @param document_id - document id
 * @return {Promise<Object>} document's and its subdocument's tasks
 */
async function get_document_tasks(user_id, document_id) {
	cache_utils.clear('cached_document', document_id);
	cache_utils.clear('cached_document_short', document_id);
	const possible_document_tasks = [];
	const document = await kaialpha.lib.document.get_user_document(user_id, document_id, 'HEAD');
	possible_document_tasks.push({
		type: 'document',
		id: document_id,
		version: document.version,
		name: document.name,
		workflow: document.workflow,
		permissions: document._cached_canonical_permissions || document.permissions,
	});

	//Fetch subdocuments tasks
	if (/* false */ get_document_tasks === get_user_tasks) {
		/*
		 * We currently don't track subdocument tasks
		 */
		const possible_subdocument_tasks = await get_subdocuments_tasks(user_id, document);
		possible_document_tasks.push(...possible_subdocument_tasks);
	}

	return await task_list(possible_document_tasks);
}

/***
 * Returns subdocument tasks
 * @param {string} user_id
 * @param {object} doc
 * @return {Promise<Array.<{id, version, workflow}>>} Array of possible tasks
 */
async function get_subdocuments_tasks(user_id, doc, possible_tasks){
	const subdocument_ids = [];
	if (!possible_tasks){
		possible_tasks = [];
	}
	const subdocument_elements = doc.subdocuments || {};
	for (const element_value of Object.values(subdocument_elements)) {
		subdocument_ids.push(...element_value.document_id);
		await Promise.all(subdocument_ids.map(async (subdocument_id) => {
			const subdocument = await kaialpha.lib.document.get_user_document(user_id, subdocument_id, 'HEAD');
			possible_tasks.push({
				type: 'document',
				id: subdocument_id,
				version: subdocument.version,
				name: subdocument_id.name,
				workflow: subdocument.workflow,
				permissions: subdocument.permissions
			});
			await get_subdocuments_tasks(user_id, subdocument, possible_tasks);
		}));
	}
	return possible_tasks;
}

/**
 * Returns template's tasks
 * @param user_id - user id
 * @param template_id - template id
 * @return {Promise<Object>} template's tasks
 */
async function get_template_tasks(user_id, template_id) {
	cache_utils.clear('cached_document', template_id);
	cache_utils.clear('cached_document_short', template_id);
	const possible_template_tasks = [];
	const template = await kaialpha.lib.template.get_user_template(user_id, template_id, 'HEAD');
	possible_template_tasks.push({
		type: 'template',
		id: template_id,
		version: template.version,
		name: template.name,
		workflow: template.workflow,
		permissions: template._cached_canonical_permissions || template.permissions,
	});

	return await task_list(possible_template_tasks);
}

const _to_export_auto = {
	get_document_tasks,
	get_template_tasks,
	clear_cache,
	get_user_tasks
};
export default _to_export_auto;
