/*
 * DO NOT EDIT THIS FILE
 *
 * This file has been automatically generated and any changes
 * made here will NOT be preserved
 *
 * This file was generated from: /Users/antonyjc/Development/clients/kaialpha-poc/src/kaialpha/lib/template_utils.js
 *
 * DO NOT EDIT THIS FILE
 */
// eslint-disable-next-line
import kaialpha from '../kaialpha';
import document_utils from './document_utils';
import object_utils from './object_utils';
import general_utils from './general_utils';
import recursive_object_utils from './recursive_object_utils';
const uuid = require('uuid');
const fs = require('fs');
const _testing = undefined;

async function process_template_references(user_id, body, template_id, cache_object, options = {}) {
	const reference_elements = [];

	if (cache_object === undefined || cache_object === null) {
		cache_object = {};
	}
	const body_map = cache_object;

	if (body !== undefined && body !== null) {
		body_map[template_id] = document_utils.body_element_map(body);
	}

	for (const body_element_id in body_map[template_id]) {
		const body_element = body_map[template_id][body_element_id];
		if (body_element.type === 'reference') {
			reference_elements.push(body_element);
		}
	}

	const variables = {};
	for (const reference_element of reference_elements) {
		const reference_name = reference_element.name;
		if (!reference_name) {
			continue;
		}

		const reference_variable_name = reference_name.toLowerCase();

		let target;
		if (typeof(reference_element.value) === 'string') {
			/*
			 * If the reference is a string, it is
			 * to a single element within the same
			 * template -- convert this to the canonical
			 * object form.
			 */
			target = {
				element_id: reference_element.value
			};
		} else {
			target = reference_element.value;
		}

		if (!target || target.element_id === undefined) {
			if (options.add_missing_variables) {
				variables[reference_variable_name] = options.add_missing_variables(reference_name);
			}
			continue;
		}

		if (!target.template_id) {
			target['template_id'] = template_id;
		}

		/*
		 * If the reference is to another template, we may
		 * need to fetch it
		 */
		if (body_map[target.template_id] === undefined) {
			try {
				const subtemplate = await kaialpha.lib.template.get_user_template(user_id, target.template_id, target.template_version);
				body_map[target.template_id] = document_utils.body_element_map(subtemplate.body);
			} catch (fetch_template_error) {
				/* Fetch error is ignored */
			}
		}

		if (body_map[target.template_id] === undefined || body_map[target.template_id][target.element_id] === undefined) {
			if (options.add_missing_variables) {
				variables[reference_variable_name] = options.add_missing_variables(reference_name);
			}
			continue;
		}

		const target_element = body_map[target.template_id][target.element_id];

		const replacement_values = {
			value: document_utils.get_value_from_element(target_element),
			type: target_element.type,
			parent_value: reference_element.value.parent
		};

		const reference_value = document_utils.get_reference_value(reference_element.value, reference_element.value.element_id, reference_element.name, replacement_values);

		variables[reference_variable_name] = reference_value;
	}

	return(variables);
}

async function process_template_style(user_id, body, template_id, cache_object, options = {}) {
	const style_elements = [];

	if (cache_object === undefined || cache_object === null) {
		cache_object = {};
	}
	const body_map = cache_object;

	if (body !== undefined && body !== null) {
		body_map[template_id] = document_utils.body_element_map(body);
	}

	for (const body_element_id in body_map[template_id]) {
		const body_element = body_map[template_id][body_element_id];
		if (body_element.type === 'style') {
			style_elements.push(body_element);
		}
	}

	const variables = {};
	let section_map = {};
	for (const style_element of style_elements) {
		const style_value = style_element.value;

		if (style_value.numbering_scheme) {
			section_map = document_utils.generate_numbering(body,[]);
		}

		const style_name = `${style_value.element_type}_style`;

		const target = style_name;

		if (!target) {
			if (options.add_missing_variables) {
				variables[style_name] = options.add_missing_variables(style_name);
			}
			continue;
		}

		/* Convert inches to em for certain styles */
		for (const key in style_value) {
			if (key === 'padding_left' || key === 'padding_right' || key === 'text_indent') {
				const style_value_em = style_value[key] * 6.0225;
				style_value[key] = style_value_em;
			}
		}

		variables[style_name] = style_value;
	}

	variables['section_map'] = section_map;

	return(variables);
}

/**
 * @typedef {Object} QualifiedVariableInfoEntry
 * @property {string} type - Type of variable
 * @property {string} [description] - Description for variable
 * @property {{id: string, version: string}[]} from - Provenance of variable
 */
/**
 * @typedef {{[qv: string]: QualifiedVariableInfoEntry}} QualifiedVariableInfo
 */
/**
 * Return the tree of variables that are defined for a given template.  This
 * will start at the top-levels and return every possible variable from a
 * given template as well as information about the provenance of those
 * variables
 *
 * @param {KaiAlphaUserID} user_id - The user ID to use to perform the lookup
 * @param {KaiAlphaCMSItemID} template_id - The ID of the template to begin the lookup
 * @returns {Promise<QualifiedVariableInfo>} Names of variables defined in the current template (?????)
 */
async function get_qualified_variable_names_for_template(user_id, template_id) {
	const tree = await kaialpha.lib.template_utils.get_template_all_variables(user_id, template_id);
	const qualifiedNames = kaialpha.lib.template_utils.flatten_template_variable_tree(tree, ['global']);

	return(qualifiedNames);
}

function normalize_variable_name(variable_name) {
	const new_variable_buffer = [];
	let relative_to_global = false;
	for (const variable_name_part of variable_name.split('.')) {
		if (variable_name_part === 'global') {
			relative_to_global = true;
			new_variable_buffer.splice(0);
		}

		if (variable_name_part === 'parent') {
			if (relative_to_global) {
				new_variable_buffer.splice(-1);

				if (new_variable_buffer.length === 0) {
					new_variable_buffer.push('global');
				}
				continue;
			}
		}

		new_variable_buffer.push(variable_name_part);
	}
	variable_name = new_variable_buffer.join('.');

	return(variable_name);
}

function _get_workflow_options(options, template_id, version_id) {
	options = {
		slot_name: 'default',
		type: 'template',
		id: template_id,
		version: version_id,
		get_user_item: async function(user_id, template_id, version_id) {
			return(await kaialpha.lib.template.get_user_template(user_id, template_id, version_id));
		},
		load_workflow_state: async function(user_id) {
			const template_info = await options.get_user_item(user_id, options.id, options.version);
			const slot_name = options.slot_name;

			if (template_info && template_info.workflow && template_info.workflow[slot_name]) {
				if (options.rerun !== true) {
					return(template_info.workflow[slot_name]);
				}
			}
			// Start running the workflow if one is not already running
			const start_slot_name = slot_name === 'default' ? '@default_new_template' : slot_name;
			return await kaialpha.lib.workflow_utils.load_workflow_state(user_id, start_slot_name);
		},
		/**
		 * Applies diff on current template. `options.version` is auto-updated
		 * to reflect latest version. If the options object is cloned before using,
		 * you need to handle the update to cloned options object manually.
		 */
		apply_diff: async function(user_id, summary, diff) {
			const retval = await kaialpha.lib.template.apply_diff_user_template(user_id, options.id, options.version, summary, diff);

			options.update_version(retval);

			return(retval);
		},
		save_workflow_state: async function(user_id, new_state) {
			const slot_name = options.slot_name;

			const summary = 'Updated Workflow State';
			const diff = {
				delete: {
					workflow: {
						[slot_name]: null
					}
				},
				change: {
					workflow: {
						[slot_name]: new_state
					}
				}
			};

			return(await options.apply_diff(user_id, summary, diff, {
				update_item_disable_permissions_check: true
			}));
		},
		update_version: function(new_version) {
			/**
			 * Note: This only updates the current reference to options.
			 * If the options are cloned, the version needs to be updated
			 * in the cloned object as well.
			 */
			options.version = new_version.version;

			return({
				id: options.id,
				version: options.version
			});
		},
		...options
	};

	return(options);
}

/* XXX:TODO: Move complete_document_workflow_event to lib/document since it can never be called client side */
async function complete_template_workflow_event(user_id, document_id, version_id, event, args = {}, options = {}) {
	options = _get_workflow_options(options, document_id, version_id);

	return(await kaialpha.lib.workflow_utils.complete_workflow_event(user_id, event, args, options));
}

/* XXX:TODO: Move post_document_workflow_event to lib/document since it can never be called client side */
async function post_document_workflow_event(user_id, template_id, version_id, event, args = {}, options = {}) {
	options = _get_workflow_options(options, template_id, version_id);

	return(await kaialpha.lib.workflow_utils.post_workflow_event(user_id, event, args, options));
}

/**
 * Resolve an unqualified variable name into a fully qualified variable name.
 * Since the name may be relative, a template ID where the variable reference
 * occurs and a map of all fully qualified variables is needed.
 *
 * @param {string} variable_name - Unqualified variable name
 * @param {KaiAlphaCMSItemID} relative_to_template_id - Template ID where the reference occurs
 * @param {QualifiedVariableInfo} qualified_names - Mapping of all qualified variable names and where they came from (from get_qualified_variable_names_for_template)
 * @returns {string[]} Fully qualified names of this variable
 */
function resolve_variable_name(variable_name, relative_to_template_id, qualified_names) {
	/*
	 * If the name is already qualified, return
	 */
	variable_name = normalize_variable_name(variable_name);
	if (variable_name.slice(0, 7) === 'global.') {
		return([variable_name.toLowerCase()]);
	}

	/*
	 * Prefix with the template ID
	 */
	let template_prefix;
	for (const qualified_name in qualified_names) {
		const variable_info = qualified_names[qualified_name];
		const variable_from_path = variable_info.from;
		const variable_from_id = variable_from_path.slice(-1)[0].id;
		if (variable_from_id === relative_to_template_id) {
			template_prefix = qualified_name.split('.').slice(0, variable_from_path.length).join('.');

			break;
		}
	}

	if (!template_prefix) {
		return([]);
	}

	variable_name = `${template_prefix}.${variable_name}`.toLowerCase();
	variable_name = normalize_variable_name(variable_name);

	/*
	 * Constrain to one of the variables that are defined
	 */
	const variable_name_split = variable_name.split('.');
	for (const qualified_name_mixed in qualified_names) {
		const qualified_name = qualified_name_mixed.toLowerCase();
		const qualified_name_component_len = qualified_name.split('.').length;
		const variable_name_slice = variable_name_split.slice(0, qualified_name_component_len).join('.');
		if (qualified_name === variable_name_slice) {
			return([qualified_name]);
		}
	}

	/*
	 * Otherwise, take a guess
	 */
	return([variable_name]);
}

async function process_template_variables(user_id, template_id, version_id, options = {}, path = [], global_name = undefined, template_path = []) {
	options = Object.assign({
		seen_ids: {},
		exclude_template_ids: [],
	}, options);

	/*
	 * Avoid loops by remembering which paths we have visited
	 */
	if (options.seen_ids[template_id]) {
		return({});
	}
	options.seen_ids[template_id] = true;

	const template = await kaialpha.lib.template.get_user_template(user_id, template_id, version_id);
	const local_variables = template.variables ? template.variables : {};
	const subtemplates = await document_utils.body_templates(template.body);
	const qualified_variables = {};
	const template_key = {
		id: template_id,
		version: version_id
	};
	const child_template_path = [...template_path, template_key];

	if (!options.exclude_template_ids.includes(template_id)) {
		for (const variable_name in local_variables) {
			const variable_info = local_variables[variable_name];
			variable_info['from'] = child_template_path;
			variable_info['__user_variable'] = true;
			recursive_object_utils.set(qualified_variables, path, variable_name, variable_info);
		}

		if (global_name !== undefined) {
			qualified_variables[global_name] = local_variables;
		}
	}

	for (const subtemplate_info of subtemplates) {
		const subtemplate = subtemplate_info.contents;
		if (!subtemplate.name) {
			continue;
		}

		const child_path = [...path, subtemplate.name]

		const subtemplate_variables = await process_template_variables(user_id, subtemplate.id, subtemplate.version, options, child_path, subtemplate.global_name, child_template_path);
		const subtemplate_variables_only = recursive_object_utils.get(subtemplate_variables, path, subtemplate.name);

		local_variables[subtemplate.name] = subtemplate_variables_only;
		recursive_object_utils.set(qualified_variables, path, subtemplate.name, subtemplate_variables_only);

		if (subtemplate.global_name) {
			qualified_variables[subtemplate.global_name] = subtemplate_variables[subtemplate.global_name];
		}
	}

	return(qualified_variables);
}

/*
 * Find the templates which include a given template
 */
async function get_template_supers(user_id, template_id, options = {}) {
	/* NOTE: We intentionally do not make a copy of "options" here */
	const retval = [];

	if (options.templates_info === undefined) {
		options.templates_info = {};
	}

	if (options.templates_info[template_id] === undefined) {
		options.templates_info[template_id] = await kaialpha.lib.template.get_user_templates(user_id, {
			fields: ['version', 'metadata'],
			filter: [`@subtemplates=${template_id}`],
			auto_paginate: true
		});
	}

	const templates = options.templates_info[template_id].templates;

	for (const template_info of templates) {
		retval.push({
			id: template_info.id,
			version: template_info.version,
			metadata: template_info.metadata
		});
	}

	if (options.include_self !== false) {
		const template_info = await kaialpha.lib.template.get_user_template(user_id, template_id, 'HEAD');
		retval.push({
			id: template_info.id,
			version: template_info.version,
			metadata: template_info.metadata
		});
	}

	return(retval);
}

/*
 * Walk the template graph and determine the top-level templates which include this one
 */
async function get_template_tops(user_id, template_id, options = {}) {
	/* NOTE: We intentionally do not make a copy of "options" here */
	if (options.seen_ids === undefined) {
		options.seen_ids = {};
	}

	const retval = [];
	const super_templates_info = await get_template_supers(user_id, template_id, options);

	/*
	 * Ensure we do not traverse the graph multiple times by marking nodes we are
	 * about to process as visited
	 */
	const local_seen_ids = {...options.seen_ids};
	for (const super_template_info of super_templates_info) {
		options.seen_ids[super_template_info.id] = true;
	}

	for (const super_template_info of super_templates_info) {
		if (local_seen_ids[super_template_info.id]) {
			continue;
		}

		if (super_template_info.metadata && (super_template_info.metadata.toplevel === 'true'
			|| (super_template_info.metadata.system && super_template_info.metadata.system.toplevel === "true"))) {
			retval.push({
				id: super_template_info.id,
				version: super_template_info.version
			});
		}

		retval.push(...(await get_template_tops(user_id, super_template_info.id, options)));
	}

	return(retval);
}

/*
 * For a given template, what variables could possibly be accessed ?
 *
 * XXX: This is currently all relative to "global" (i.e., absolute), in the
*       future it might be desirable to make these relative.
 */
async function get_template_all_variables(user_id, template_id, options = {}) {
	options = Object.assign({
		exclude_template_ids: []
	}, options);

	const supers = await get_template_tops(user_id, template_id);
	const variables = {};

	for (const top_level_template of supers) {
		const top_level_template_variables = await process_template_variables(user_id, top_level_template.id, top_level_template.version, options);
		Object.assign(variables, top_level_template_variables);
	}

	return(variables);
}

/**
 * Flatten a template variable tree into a key value representation
 *
 * @param {Object} tree - Tree of variables
 * @param {string[]} [path] - Path information
 * @returns {QualifiedVariableInfo} Variable information but in a format where the keys are the variable names
 */
function flatten_template_variable_tree(tree, path = []) {
	/** @type {QualifiedVariableInfo} */
	const retval = {};

	for (const key in tree) {
		const variable = tree[key];
		const variable_path = [...path, key];

		if (!(variable instanceof Object)) {
			continue;
		}

		if (variable.__user_variable === true) {
			const user_variable = object_utils.copy_object(variable);
			const user_variable_name = variable_path.join('.');

			delete user_variable['__user_variable'];

			retval[user_variable_name] = user_variable;
		}

		const sub_tree_flattened = flatten_template_variable_tree(variable, variable_path);

		Object.assign(retval, sub_tree_flattened);
	}

	return(retval);
}

function arrange_tree_in_template_order(tree, path = [], retval = {}) {
	for (const key in tree) {
		const variable = tree[key];

		if (!(variable instanceof Object)) {
			continue;
		}

		const sub_path = [...path, key];
		if (variable.__user_variable === true) {
			const tree_path = [];
			for (const template_key of variable.from) {
				const template_id = template_key.id;
				const template_version = template_key.version;

				tree_path.push(template_id);
				tree_path.push(template_version);
			}

			const user_variable = object_utils.copy_object(variable);

			delete user_variable['from'];
			delete user_variable['__user_variable'];
			user_variable['variable_path'] = sub_path;

			recursive_object_utils.set(retval, [...tree_path, '__user_variables'], key, user_variable);
		}

		arrange_tree_in_template_order(variable, sub_path, retval);
	}

	return(retval);
}

function checkOrdinalNameAndFormat(name) {
	if (name.includes("{{") && name.includes("}}")) {
		const string = name.substring(
			name.lastIndexOf("{{") + 1,
			name.lastIndexOf("}}")
		);
		if (!(string.length > 2)) {
			name = name + "_{{ordinal}}";
		}
	} else {
		name = name + "_{{ordinal}}";
	}
	return name;
}

async function render_template_processing_template_references(user_id, template_id, template_body) {
	/*
	 * XXX:TODO: This should process template references/metadata in the
	 * same way as "process_document_variables".
	 */
	const variables = {};
	variables['__current'] = await kaialpha.lib.template_utils.process_template_references(user_id, template_body, template_id);
	variables['global'] = variables['__current'];

	const nunjucks_file = await kaialpha.lib.generator.generateNunjucksFromBody(template_body, {
		user_id: user_id,
		type: 'template'
	});

	const html_file = await kaialpha.lib.generator.generateHTML(nunjucks_file, variables, {
		get_user_list_entries: async function(list_type, list_id, list_version) {
			return(await kaialpha.lib.list_utils.get_user_list_entries(user_id, list_type, list_id, list_version));
		}
	});

	fs.unlinkSync(nunjucks_file);

	return(html_file);
}

/**
 * @typedef {Object} variable_validation_result
 * @property {boolean} hasDuplicate - true/false
 * @property {array} all_variables - List of all variable names
 */

/**
 * @param {object} all_variables list of all existing variables
 * @param {object} options additional options
 * @param {string} options.exclude_name name to exclude from validation [Ex. When editing a variable]
 * @param {string} options.name_to_validate name to include in validation [Ex. When inserting/updating a variable name]
 * @returns {variable_validation_result} result
 */
function validate_variable_names(all_variables, options ={ exclude_name: '', name_to_validate:''}){
	const result = {
		hasDuplicate: false,
		all_variables:[]
	};

	if (!all_variables){
		return result;
	}

	let all_variable_names = Object.keys(all_variables);

	if (options.exclude_name) { //If existing variable is being edited
		all_variable_names = all_variable_names.filter((variable) => variable.toLowerCase() !== options.exclude_name);
	}

	result['all_variables'] = all_variable_names;
	const variable_countMap =  new Map();

	if (options.name_to_validate){ //If inserting/updating a variable name
		variable_countMap.set(options.name_to_validate.toLowerCase(), 1)
	}
	for (let i = 0; i< all_variable_names.length; i++){
		const variableName = all_variable_names[i].toLowerCase().trim();
		if (variable_countMap.has(variableName)){
			variable_countMap.set(variableName, variable_countMap.get(variableName)+1)
		} else {
			variable_countMap.set(variableName, 1);
		}
	}

	const dupliate_names = []
	variable_countMap.forEach((val, key) => {
		if (val > 1) {
			dupliate_names.push(key)
		}
	});

	if (dupliate_names.length > 0){
		result['hasDuplicate'] = true;
	} else {
		result['hasDuplicate'] = false;
	}

	return result;

}

if (_testing) {

	const body = [
		{
			[uuid.v4()]: {
				type: 'title',
				title: 'A Report for Customer {{CustomerID}}'
			}
		},
		{
			[uuid.v4()]: {
				type: 'variable',
				name: 'CustomerID'
			}
		},
		{
			[uuid.v4()]: {
				type: 'variable',
				name: 'CustomerType'
			}
		},
		{
			[uuid.v4()]: {
				type: 'html',
				text: 'This is a report for Customer {{CustomerID}} ({{CustomerType}})'
			}
		},
		{
			[uuid.v4()]: {
				type: 'reference',
				name: 'CustomerType'
			}
		}
	];

	_testing.process_template_references = function () {
		process_template_references('user1', body, 'templateId');
		return true;
	}

	_testing.generate_numbering = function () {

		const body_elements = [...body];

		body_elements.push({
			[uuid.v4()]: {
				type: 'section',
				name: 'Section'
			}
		})

		const result = document_utils.generate_numbering(["1"], {}, body_elements, 0, false);

		/* istanbul ignore if */
		if (Object.entries(result)[0][1] !== "2") {
			throw new Error("Has sub sections but not found");
		}
		return true;
	}

	_testing.generate_numbering_with_true = function () {

		const body_elements = [...body];

		const section = {
			[uuid.v4()]: {
				type: 'section',
				name: 'Section'
			}
		}

		body_elements.push(section);

		const result = document_utils.generate_numbering("2.1", {}, body_elements, 1, true);

		/* istanbul ignore if */
		if (Object.entries(result)[0][1] !== "2.2") {
			throw new Error("Numbering is wrong");
		}
		return true;
	}

	_testing.generate_numbering_with_body = function () {

		const body_elements = [...body];

		const section = {
			[uuid.v4()]: {
				type: 'section',
				name: 'Section',
				body: {
					[uuid.v4()]: {
						type: 'section',
						name: 'Section'
					}
				}
			}
		}

		body_elements.push(section);

		const result = document_utils.generate_numbering("1", {}, body_elements, 0, false);

		/* istanbul ignore if */
		if (Object.entries(result)[0][1] !== "2") {
			throw new Error("Number should be 2 but found differnet");
		}
		return true;
	}

	_testing.generate_numbering_with_nested_section_body = function () {

		const body_elements = [...body];

		body_elements.push({
			[uuid.v4()]: {
				type: 'section',
				name: 'Section',
				body: {}
			}
		})

		const subsection = body_elements[body_elements.length - 1];
		const subsection_values = Object.values(subsection)[0];
		const subsection_body = subsection_values['body'];

		subsection_body[uuid.v4()] = {
			[uuid.v4()]: {
				type: 'section',
				name: 'Section',
				body: {
					[uuid.v4()]: {
						type: 'html',
						text: 'Text'
					}
				}
			},
		}

		subsection_body[uuid.v4()] = {
			[uuid.v4()]: {
				type: 'section',
				name: 'Section'
			}
		}

		const result = document_utils.generate_numbering("1", {}, body_elements, 1, true);

		/* istanbul ignore if */
		if (Object.entries(result)[2][1] !== "2.2") {
			throw new Error("Number should be 2.2 but found differnet");
		}
		return true;
	}

	_testing.flatten_template_variable_tree = function () {
		const variables = {
			"Var1": {
				description: "{{Var1}}",
				type: "richtextarea",
				__user_variable: true
			}
		}
		const result = flatten_template_variable_tree(variables);

		/* istanbul ignore if */
		if (result.Var1 && result.Var1.type !== 'richtextarea') {
			throw new Error("Var1 should hold in the flattern tree but not found");
		}
		return true;
	}

	_testing.arrange_tree_in_template_order = function () {
		const variables = {
			"Var1": {
				description: "{{Var1}}",
				type: "richtextarea",
				__user_variable: true,
				'from': [{
					[uuid.v4()]: {
						'id': 'tempalteId',
						'version':'tempateVersion',
					}
				}]
			}
		}

		const result = arrange_tree_in_template_order(variables);

		/* istanbul ignore if */
		if (!result) {
			throw new Error("Resulting with no variables which is not exprected");
		}
		return true;
	}

	_testing.render_template_processing_template_references = async function () {
		const result = await render_template_processing_template_references('user1', null, []);

		/* istanbul ignore if */
		if (!result) {
			throw new Error("Nothing got rendered");
		}
		return true;
	}
}

class TemplateBody extends document_utils.Body {
	constructor(body, template) {
		super(body, { type: 'template', container: template });
	}
}

/**
 * Template instance class
 */
class Template extends general_utils.GeneralCMS {
	constructor(ka_context, contents, metadata) {
		super('template', ka_context, contents, metadata);
	}

	async wait() {
		await super.wait();
	}

	_assert_template() {
		return(this._assert_contents());
	}

	template() {
		return(this._assert_template());
	}

	body() {
		const template = this.template();

		const bodyObject = new TemplateBody(template.body, this);

		return(bodyObject);
	}

	children() {
		const retval = [];

		const body = this.body().value;
		if (!body) {
			return(retval);
		}

		const sub_templates = kaialpha.lib.document_utils.body_by_element_tag('template', body);
		const our_id = this.id();

		for (const element_info of sub_templates) {
			const element_id = element_info.id;
			const element_contents = element_info.contents;
			const template_id = element_contents.id;
			const template_version = element_contents.version;

			if (!this._children_at) {
				this._children_at = {};
			}

			if (!this._children_at[element_id]) {
				this._children_at[element_id] = new Template(this._ka_context, {
					id: template_id,
					version: template_version
				}, {
					parent: our_id,
					parent_element: element_id
				});
			}

			if (template_id && template_version) {
				retval.push(this._children_at[element_id]);
			}
		}

		return(retval);
	}

	variables() {
		const template = this.template();
		const variables = {
			...template.variables
		};

		return(variables);
	}

	async _tops_or_parents(mode) {
		const retval = [];

		await this.wait();
		if (Object.keys(this._changed).length > 0 || this._new) {
			throw(new Error('Unable to get parents with an unsaved template'));
		}

		let parents = await get_template_supers(this._user_id, this._id);
		let our_id;
		switch (mode) {
			case 'parents':
				parents = await get_template_supers(this._user_id, this._id);
				our_id = await this.id();
				break;
			case 'tops':
				parents = await get_template_tops(this._user_id, this._id);
				break;
			default:
				throw(new Error(`internal error: only parents or tops is valid, got: ${mode}`));
		}

		for (const parent_info of parents) {
			const template_id = parent_info.id;
			const template_version = parent_info.version;

			if (template_id === our_id) {
				continue;
			}

			if (!this._parents_info) {
				this._parents_info = {};
			}

			const key = ['template', template_id].join('_');
			if (this._parents_info[key]) {
				const template_version_check = this._parents_info[key].version();
				if (template_version_check !== template_version) {
					delete this._parents_info[key];
				}
			}

			if (!this._parents_info[key]) {
				this._parents_info[key] = new Template(this._ka_context, {
					id: template_id,
					version: template_version
				});
			}

			retval.push(this._parents_info[key]);
		}

		return(retval);
	}

	async parents() {
		return(this._tops_or_parents('parents'));
	}

	async tops() {
		return(this._tops_or_parents('tops'));
	}
}

const _to_export_auto = {
	Template,
	process_template_variables,
	process_template_references,
	process_template_style,
	get_template_supers,
	get_template_tops,
	get_template_all_variables,
	flatten_template_variable_tree,
	arrange_tree_in_template_order,
	render_template_processing_template_references,
	checkOrdinalNameAndFormat,
	get_qualified_variable_names_for_template,
	resolve_variable_name,
	post_document_workflow_event,
	validate_variable_names,
	complete_template_workflow_event,
	_get_workflow_options,
	_testing
}
export default _to_export_auto;
