/*
 * DO NOT EDIT THIS FILE
 *
 * This file has been automatically generated and any changes
 * made here will NOT be preserved
 *
 * This file was generated from: /Users/antonyjc/Development/clients/kaialpha-poc/src/kaialpha/lib/diff_utils.js
 *
 * DO NOT EDIT THIS FILE
 */
// eslint-disable-next-line
import kaialpha from '../kaialpha';
import recursive_object from './recursive_object_utils';
import object_utils from './object_utils';
import testing_utils from './testing_utils';
const diff_match_patch = require('diff-match-patch');
const _testing = undefined;

/**
 * Compute a diff between two objects
 * @param {*} object_old
 * @param {*} object_new
 * @param {Object} options
 * @param {boolean} [options.elaborate_arrays] - Elaborate array changes with added and deleted elements instead of single "change"
 * @returns
 */
function diff_objects(object_old, object_new, options = {}) {

	options = {
		/**
		 * By default, the diff_objects function looks at array diffs as a single change
		 * rather than treating them as multiple individual changes.
		 * For example, adding one element and deleting another element will be considered as single change instead two changes.
		 * Defaulting to false for now for backwards compatibility and avoiding unintentional side effects.
		 * This can be set to true by default after evaluating any side effects while saving documents body_extend and templates body.
		 */
		elaborate_arrays: false,
		...options,
	}

	const retval = {
		changed: {},
		added: {},
		deleted: {}
	};

	recursive_object.exec(object_old, function(object, path, key) {
		const value_old = object[key];

		const value_new = recursive_object.get(object_new, path, key);

		if (value_old === undefined && value_new === undefined) {
			return;
		}

		if (value_old !== undefined && value_old instanceof Array && value_new instanceof Array) {
			if (kaialpha.lib.object_utils.object_equals(value_old, value_new)) {
				return;
			}

			if (options.elaborate_arrays === true) {
				// if the elements only exists in old object, it is deleted
				const deleted_items = value_old.filter(old_element => !value_new.find(new_element => kaialpha.lib.object_utils.object_equals(new_element, old_element)));

				// if the element only exists in new object, it is added
				const added_items = value_new.filter(new_element => !value_old.find(old_element => kaialpha.lib.object_utils.object_equals(new_element, old_element)));

				retval.deleted = recursive_object.set(retval.deleted, path, key, [deleted_items, null]);
				retval.added = recursive_object.set(retval.added, path, key, [null, added_items]);
				return;
			}

		}

		if (value_old === value_new) {
			return;
		}

		if (value_new === undefined) {
			retval.deleted = recursive_object.set(retval.deleted, path, key, [value_old, null]);
			return;
		}

		if (value_old === undefined) {
			/* XXX: This shouldn't happen */
			retval.added = recursive_object.set(retval.added, path, key, [null, value_new]);
		}

		retval.changed = recursive_object.set(retval.changed, path, key, [value_old, value_new]);
	});

	recursive_object.exec(object_new, function(object, path, key) {
		const value_new = object[key];
		const value_old = recursive_object.get(object_old, path, key);

		if (value_old !== undefined && value_old instanceof Array){
			if (kaialpha.lib.object_utils.object_equals(value_old, value_new)) {
				return;
			}
		}

		if (value_old === value_new) {
			return;
		}

		if (value_new === undefined) {
			/* XXX: This shouldn't happen */
			retval.deleted = recursive_object.set(retval.deleted, path, key, [value_old, null]);
			return;
		}

		if (value_old === undefined) {
			retval.added = recursive_object.set(retval.added, path, key, [null, value_new]);
		}
	});

	['changed', 'added', 'deleted'].forEach(function(element) {
		if (Object.keys(retval[element]).length === 0) {
			delete retval[element];
		}
	});

	return(retval);
}

if (_testing) {
	_testing.diff_objects = function() {
		const object = {
			test: 'example'
		}
		const result = diff_objects(object, []);
		/* istanbul ignore if */
		if (!result.deleted.test) {
			throw new Error(`Expected example but found empty`);
		}
		return true;
	}

	_testing.diff_objects_undefined = function() {
		const result = diff_objects({'test':undefined}, []);
		/* istanbul ignore if */
		if (Object.keys(result).length > 0) {
			throw new Error(`Expected an empty object but found, ${result}`);
		}

		return true;
	}

	_testing.diff_objects_array = function() {
		const result = diff_objects({test : ['something']}, {test: ['something']});
		/* istanbul ignore if */
		if (Object.keys(result).length > 0) {
			throw new Error(`Expected an empty object but found, ${result}`);
		}
		return true;
	}

	_testing.diff_objects_nested_with_array = function () {
		let result;
		result = diff_objects({ test: { nested: { deep: ['something'] } } }, { test: { nested: { deep: ['something'] } } }, { elaborate_arrays: true });
		kaialpha.lib.testing_utils.assert(Object.keys(result).length === 0, `Expected an empty object but found, ${result}`);

		result = diff_objects({ test: { nested: { deep: ['unchanged', 'deleted'] } } }, { test: { nested: { deep: ['unchanged', 'added'] } } }, { elaborate_arrays: true });
		kaialpha.lib.testing_utils.assert(Object.keys(result.added).length > 0, `Expected added elements in array but found nothing.`, result);
		kaialpha.lib.testing_utils.assert(Object.keys(result.deleted).length > 0, `Expected delete elements in array but found nothing.`, result);

		result = diff_objects({ test: { nested: { deep: [{ a: 1 }, 'deleted'] } } }, { test: { nested: { deep: [{ a: 2 }, 'added'] } } }, { elaborate_arrays: true });
		kaialpha.lib.testing_utils.assert.object_equals(result.added.test.nested.deep[1], [{ a: 2 }, 'added'], `Expected added elements not found.`);
		kaialpha.lib.testing_utils.assert.object_equals(result.deleted.test.nested.deep[0], [{ a: 1 }, 'deleted'], `Expected deleted elements not found.`);
		return true;
	};
}

/**
 * Utility function to return a sentinel value if the object is not defined
 *
 * @param {any} object - Value to return
 * @param {any} [value] - Value to return if "object" is null or undefined
 * @returns {any} The value of either "value" or "object"
 */
function _denull(object, value = "") {
	if (object === undefined || object === null) {
		return(value);
	}

	return(object);
}

/**
 * Perform a 3-way merge given 2 forks of a common base
 * @param {Object} object_1
 * @param {Object} object_2
 * @param {Object} object_base
 * @param {Object} [options]
 * @param {Object} [options.strict] - If false, this will force update the object with new changes. Default is true.
 * @param {boolean} [options.elaborate_arrays] - Combine the arrays as multiple individual changes.
 * For example, adding one element and deleting another element will be considered as two changes
 * instead one big change.
 * @returns {Object}
 */
function merge_objects(object_1, object_2, object_base, options = {}) {
	const diff_1 = diff_objects(object_base, object_1, options);
	const diff_2 = diff_objects(object_base, object_2, options);
	const flat_1 = {};
	const flat_2 = {};

	/*
	 * Flatten the added/changed/deleted keys -- they are in the same format
	 * so they can be handled the same.
	 */
	['changed', 'added', 'deleted'].forEach(function(element) {
		recursive_object.exec(diff_1[element], function(diff_object, path, key) {
			const value = diff_object[key];
			recursive_object.set(flat_1, path, key, value);
		});
		recursive_object.exec(diff_2[element], function(diff_object, path, key) {
			const value = diff_object[key];
			recursive_object.set(flat_2, path, key, value);
		});
	});

	/*
	 * For any keys that have changed from the baseline in both versions,
	 * try to compute a text-based diff.
	 */
	let diff_program;
	recursive_object.exec(flat_1, function(diff_object, path, key) {
		const flat_2_values = recursive_object.get(flat_2, path, key);
		if (!flat_2_values) {
			return;
		}
		const flat_1_values = diff_object[key];
		const flat_1_old_value = _denull(flat_1_values[0]);
		const flat_1_new_value = _denull(flat_1_values[1]);
		const flat_2_old_value = _denull(flat_2_values[0]);
		const flat_2_new_value = _denull(flat_2_values[1]);

		if (typeof(flat_1_old_value) !== 'string') {
			return;
		}
		if (typeof(flat_1_new_value) !== 'string') {
			return;
		}
		if (typeof(flat_2_old_value) !== 'string') {
			return;
		}
		if (typeof(flat_2_new_value) !== 'string') {
			return;
		}

		const base_value = recursive_object.get(object_base, path, key);

		if (!diff_program) {
			diff_program = new diff_match_patch();
		}
		const flat_1_diff = diff_program.diff_main(flat_1_old_value, flat_1_new_value);
		const flat_2_diff = diff_program.diff_main(flat_2_old_value, flat_2_new_value);
		const flat_1_patch = diff_program.patch_make(flat_1_old_value, flat_1_diff);
		const flat_2_patch = diff_program.patch_make(flat_2_old_value, flat_2_diff);

		const [new_value_1, apply_result_1] = diff_program.patch_apply(flat_1_patch, _denull(base_value));
		if (apply_result_1) {
			const [new_value_2, apply_result_2] = diff_program.patch_apply(flat_2_patch, new_value_1);

			if (apply_result_2) {
				recursive_object.set(_denull(diff_1.added, {}), path, key, undefined)
				recursive_object.set(_denull(diff_1.changed, {}), path, key, undefined)
				recursive_object.set(_denull(diff_1.deleted, {}), path, key, undefined)
				recursive_object.set(_denull(diff_2.added, {}), path, key, undefined)
				recursive_object.set(_denull(diff_2.changed, {}), path, key, undefined)
				recursive_object.set(_denull(diff_2.deleted, {}), path, key, undefined)

				if (base_value === null) {
					if (!diff_2.added) {
						diff_2.added = {};
					}
					recursive_object.set(diff_2.added, path, key, [base_value, new_value_2]);
				} else {
					if (!diff_2.changed) {
						diff_2.changed = {};
					}
					recursive_object.set(diff_2.changed, path, key, [base_value, new_value_2]);
				}
			}
		}
	});

	const result_1 = apply_diff(object_base, diff_1, options);
	const result_2 = apply_diff(result_1, diff_2, options);

	return(result_2);
}

if (_testing) {
	_testing.merge_objects_1 = function() {
		const object_base = {
			example: 'Test 0'
		};
		const object_1 = {
			example: 'Test 0',
			more: 'Object 1'
		};
		const object_2 = {
			example: 'Test 2',
			another: 'Object 2'
		};

		const result = merge_objects(object_1, object_2, object_base, { strict: true });

		testing_utils.assert.object_equals(result, {
			example: 'Test 2',
			more: 'Object 1',
			another: 'Object 2'
		});

		return(true);
	}
	_testing.merge_objects_2 = function() {
		const object_base = {
			example: 'Test 0'
		};
		const object_1 = {
			example: 'Test 1',
			more: 'Object 1'
		};
		const object_2 = {
			example: 'Test 0\nTest 2',
			another: 'Object 2'
		};

		const result = merge_objects(object_1, object_2, object_base, { strict: true });

		testing_utils.assert.object_equals(result, {
			example: 'Test 1\nTest 2',
			more: 'Object 1',
			another: 'Object 2'
		});

		return(true);
	}
	_testing.merge_objects_3 = function() {
		const object_base = {
		};
		const object_1 = {
			example: 'Test 1',
		};
		const object_2 = {
			example: 'Test 2',
		};

		const result = merge_objects(object_1, object_2, object_base, { strict: true });

		testing_utils.assert.object_equals(result, {
			example: 'Test 2Test 1'
		});

		return(true);
	}
	_testing.merge_objects_exception_1 = function() {
		const object_base = {
			example: 0,
		};
		const object_1 = {
			example: 1,
		};
		const object_2 = {
			example: 2,
		};

		try {
			merge_objects(object_1, object_2, object_base);
		} catch (err) {
			return true;
		}

		/* istanbul ignore next */
		throw new Error("As the original value of the base object is changed it should throw error but passed");
	}

	_testing.merge_objects_exception_2 = function() {
		const object_base = {
			example: 'string',
		};
		const object_1 = {
			example: 1,
		};
		const object_2 = {
			example: 2,
		};

		try {
			merge_objects(object_1, object_2, object_base);
		} catch (err) {
			return true;
		}

		/* istanbul ignore next */
		throw new Error("As the original value of the base object is changed it should throw error but passed");
	}

	_testing.merge_objects_4 = function() {
		const object_base = {
			example: 123,
		};
		const object_1 = {
			example: 123,
		};
		const object_2 = {
			example: 'new value',
		};
		const result = merge_objects(object_1, object_2, object_base);

		testing_utils.assert.object_equals(result, {
			example: 'new value'
		});

		return true;
	}

	_testing.merge_objects_exception_4 = function() {
		const object_base = {
			example: 'string'
		};
		const object_1 = {
			example: 'string change'
		};
		const object_2 = {
			example: 12
		};

		try {
			merge_objects(object_1, object_2, object_base);
		} catch (err) {
			return true;
		}

		/* istanbul ignore next */
		throw new Error("As the original value of the base object is changed it should throw error but passed");
	}
}

/**
 * Apply a diff to transform object
 * @param {Object} object
 * @param {Object} diff
 * @param {Object} [options]
 * @param {Object} [options.strict] - If false will force update the object with new changes. Default is true.
 * @param {Object} [options.elaborate_arrays] - Combine the arrays as multiple individual changes.
 * For example, adding one element and deleting another element will be considered as two changes
 * instead one big change.
 * @returns {Object}
 */
function apply_diff(object, diff, options = {}) {
	options = Object.assign({
		strict: true
	}, options);

	object = object_utils.copy_object(object);

	if (diff.added) {
		recursive_object.exec(diff.added, function(diff_object, path, key) {
			/**
			 * format of diff_values for additions = [null, added_value]
			 * @type {[null, *]}
			 */
			const diff_values = diff_object[key];
			const diff_old_value = diff_values[0];
			let diff_new_value = diff_values[1];

			if (diff_old_value !== null) {
				throw(new Error('Unable to apply diff: invalid added'));
			}

			if (options.strict) {
				const old_value = recursive_object.get(object, path, key);
				if (options.elaborate_arrays === true && Array.isArray(diff_new_value)) {
					// add elements to old array
					const updated_list = get_merged_arrays(old_value, diff_new_value);
					diff_new_value = updated_list;
				} else if (old_value !== undefined) {
					throw(new Error('Unable to apply diff: value already exists'));
				}
			}

			recursive_object.set(object, path, key, diff_new_value);
		});
	}

	if (diff.changed) {
		recursive_object.exec(diff.changed, function(diff_object, path, key) {
			const diff_values = diff_object[key];
			const diff_old_value = diff_values[0];
			const diff_new_value = diff_values[1];

			if (options.strict) {
				const old_value = recursive_object.get(object, path, key);
				if (!kaialpha.lib.object_utils.object_equals(old_value, diff_old_value)) {
					throw(new Error('Unable to apply diff: value does not match: ' + diff_old_value + ' vs ' + old_value));
				}
			}

			recursive_object.set(object, path, key, diff_new_value);
		});
	}

	if (diff.deleted) {
		recursive_object.exec(diff.deleted, function(diff_object, path, key) {
			/**
			 * format of diff_values for deletions = [deleted_value, null]
			 * @type {[*, null]}
			 */
			const diff_values = diff_object[key];
			const diff_old_value = diff_values[0];
			const diff_new_value = diff_values[1];

			if (diff_new_value !== null) {
				throw(new Error('Unable to apply diff: invalid deleted'));
			}

			/** @type {*[] | undefined} */
			let new_value_to_set = undefined;
			if (options.strict) {
				const old_value = recursive_object.get(object, path, key);
				if (options.elaborate_arrays === true && Array.isArray(old_value)) {
					/** @type {*[]} */
					const deleted_elements = diff_old_value || [];

					// Remove the deleted elements from old array
					new_value_to_set = old_value.filter(element => !deleted_elements.find(del_elem => kaialpha.lib.object_utils.object_equals(element, del_elem)));

				} else if (old_value !== diff_old_value) {
					throw(new Error('Unable to apply diff: value does not match: ' + diff_old_value + ' vs ' + old_value));
				}
			}

			recursive_object.set(object, path, key, new_value_to_set);
		});
	}

	return(object);
}

if (_testing) {
	_testing.apply_diff_1 = function() {
		const object = {
			example: 'Test'
		};

		/*
		 * Test "added"
		 */
		const result_1 = apply_diff(object, {
			added: {
				more: [null, 'More Test']
			}
		});

		/* istanbul ignore if */
		if (Object.keys(result_1).length !== 2) {
			throw(new Error('Result has wrong number of keys'));
		}

		/* istanbul ignore if */
		if (result_1.example !== 'Test') {
			throw(new Error('Key value error [example/1]'));
		}

		/* istanbul ignore if */
		if (result_1.more !== 'More Test') {
			throw(new Error('Key value error [more/1]'));
		}

		/*
		 * Test "changed"
		 */
		const result_2 = apply_diff(result_1, {
			changed: {
				more: ['More Test', 'Another Test']
			}
		});

		/* istanbul ignore if */
		if (result_2.more !== 'Another Test') {
			throw(new Error('Key value error [more/2]'));
		}

		/*
		 * Test "changed" with no strict
		 */
		const result_3 = apply_diff(result_1, {
			changed: {
				more: ['More Test', 'Another Test No Strict']
			}
		}, { strict: false });

		/* istanbul ignore if */
		if (result_3.more !== 'Another Test No Strict') {
			throw(new Error('Key value error [more/3]'));
		}

		/*
		 * Test "deleted"
		 */
		const result_4 = apply_diff(result_3, {
			deleted: {
				more: ['Another Test No Strict', null]
			}
		});

		/* istanbul ignore if */
		if (result_4.more !== undefined) {
			throw(new Error('Key value error [more/4]'));
		}

		/*
		 * Test "deleted" with no strict
		 */
		const result_5 = apply_diff(result_4, {
			deleted: {
				example: ['Bogus Value', null]
			}
		}, { strict: false });

		/* istanbul ignore if */
		if (Object.keys(result_5).length !== 0) {
			throw(new Error('Result has wrong number of keys after deleting them all'));
		}

		return(true);
	}
	_testing.apply_diff_2 = function() {

		const object = {
			example: 'test'
		}

		try {
			apply_diff(object, {
				added: object
			});
		} catch (err) {
			return true;
		}

		/* istanbul ignore next */
		throw new Error(`Expected an error here but passed the condition`);
	}

	_testing.apply_diff_3 = function() {
		try {
			apply_diff({}, {
				changed: { 'example': 'something' }
			});
		} catch (err) {
			return true;
		}

		/* istanbul ignore next */
		throw new Error(`Expected an error here but passed the condition`);
	}

	_testing.apply_diff_4 = function() {
		try {
			apply_diff({}, {
				deleted: { 'example': 'something' }
			}, { strict: true });
		} catch (err) {
			return true;
		}

		/* istanbul ignore next */
		throw new Error(`Expected an error here but passed the condition`);
	}

	_testing.apply_diff_5 = function() {
		try {
			apply_diff({ expression: ['something'] }, {
				added: { expression: [null]}
			}, { strict: true });
		} catch (err) {
			return true;
		}

		/* istanbul ignore next */
		throw new Error(`Expected an error here but passed the condition`);
	}

	_testing.apply_diff_6 = function() {
		try {
			apply_diff({ expression: ['something'] }, {
				deleted: { expression: ['something', null] }
			}, { strict: true });
		} catch (err) {
			return true;
		}

		/* istanbul ignore next */
		throw new Error(`Expected an error here but passed the condition`);
	}
}

/**
 * Combine two arrays into a set of unique values.
 * @param {*[]} array_1
 * @param {*[]} array_2
 * @returns {*[]}
 */
function get_merged_arrays(array_1, array_2) {
	array_1 = array_1 || [];
	array_2 = array_2 || [];

	// get the elements that are not in other array
	const filtered_array1 = array_1.filter(arr1_item => !array_2.find(arr2_item => kaialpha.lib.object_utils.object_equals(arr2_item, arr1_item)));

	// merge unique elements into one array
	return [...filtered_array1, ...array_2];
}

function diff_array(array_old, array_new, options = {}) {
	options = {
		compare_function: undefined,
		...options
	};

	const retval = {
		deleted: [],
		added: []
	};

	if (array_old === null) {
		array_old = [];
	}

	if (array_new === null) {
		array_new = [];
	}

	for (const value_old of array_old) {
		if (options.compare_function === undefined) {
			if (array_new.includes(value_old)) {
				continue;
			}
		} else {
			let found_in_new = false;

			for (const value_new of array_new) {
				if (options.compare_function(value_old, value_new) === 0) {
					found_in_new = true;
					break;
				}
			}

			if (found_in_new === true) {
				continue;
			}
		}

		retval.deleted.push(value_old);
	}

	for (const value_new of array_new) {
		if (options.compare_function === undefined) {
			if (array_old.includes(value_new)) {
				continue;
			}
		} else {
			let found_in_old = false;

			for (const value_old of array_old) {
				if (options.compare_function(value_old, value_new) === 0) {
					found_in_old = true;
					break;
				}
			}

			if (found_in_old === true) {
				continue;
			}
		}

		retval.added.push(value_new);
	}

	if (retval.added.length === 0) {
		delete retval['added'];
	}

	if (retval.deleted.length === 0) {
		delete retval['deleted'];
	}

	return(retval);
}

const _to_export_auto = {
	diff_objects,
	diff_array,
	merge_objects,
	apply_diff,
	_testing
}
export default _to_export_auto;
