/*
 * DO NOT EDIT THIS FILE
 *
 * This file has been automatically generated and any changes
 * made here will NOT be preserved
 *
 * This file was generated from: /Users/antonyjc/Development/clients/kaialpha-poc/src/kaialpha/lib/versions_utils.js
 *
 * DO NOT EDIT THIS FILE
 */
// eslint-disable-next-line
import kaialpha from '../kaialpha';
const MAX_INLINE_CACHE_LENGTH = (200 * 1024);

const _testing = undefined;

/*
 * Well known UIDs
 */
const system_user_id = '@system';
const system_everyone_id = '@all';
const system_nobody_id = '@nobody';

const list_generate_options_query_help = {
	fields: 'A comma separated list of fields to return',
	count: 'Specify the maximum number of results to return',
	filter: 'Specify a filter to apply to select a subset of records',
	order: 'Specify a whether the "orderby" field should be ascending (default) or descending',
	orderby: 'Specify the name of the field to sort results by',
	after: 'Specify an opaque handle to return records after, as returned by the "next" property'
};

function list_generate_options_from_query(query) {
	const options = {};

	if (!query) {
		return(options);
	}

	if (query.fields) {
		options.fields = query.fields.split(',');
	}

	if (query.count) {
		options.count = Number(query.count);
	}

	if (query.filter) {
		let filter_value = query.filter;
		if (filter_value && kaialpha.lib.object_utils.is_json_object(filter_value)) {
			try {
				filter_value = JSON.parse(filter_value);
			} catch (_ignored_filter_parse) {
				filter_value = [filter_value];
			}
		}

		options.filter = filter_value;
		let minMatch = 0;
		try {
			minMatch = parseInt(query.filter_minimum_should_match);
		} catch(exc){
			// If this isn't an int, it's fine - the default value is zero
		}
		options.filter_minimum_should_match = minMatch;
	}

	if (query.order) {
		options.order = query.order;
	}

	if (query.orderby) {
		options.orderby = query.orderby;
	}

	if (query.after) {
		// if using opensearch `after` will be stringified array
		try {
			options.after = JSON.parse(query.after);
		} catch {
			options.after = query.after;
		}
	}

	if (query.afterVersion) {
		options.afterVersion = query.afterVersion;
	}

	return(options);
}

if (_testing) {
	_testing.list_generate_options_from_query = function () {
		let result;

		const query = {
			fields: "A, B, C",
			count: "10",
			filter: "metadata = true"
		}

		result = list_generate_options_from_query();

		/* istanbul ignore if */
		if (result.fields) {
			throw new Error("Expected an empty field by found some");
		}

		result = list_generate_options_from_query(query);

		/* istanbul ignore if */
		if (!(Array.isArray(result.fields) && result.fields.length === 3 && result.count === 10 && result.filter === 'metadata = true')) {
			throw new Error(`Expected exact values for the query but found some mismatching elements: ${JSON.stringify(result)}`);
		}

		return true;
	}
}

function list_generate_query_from_options(options = {}) {
	const query_parts = [];

	if (options.fields !== undefined && options.fields.length > 0) {
		const encoded_fields = options.fields.map(function(field) {
			return(encodeURIComponent(field));

		});
		query_parts.push(`fields=${encoded_fields.join(',')}`);
	}

	if (options.count !== undefined) {
		query_parts.push(`count=${encodeURIComponent(options.count)}`);
	}

	if (options.filter !== undefined && options.filter !== '') {
		let filter_string = options.filter;
		if (Array.isArray(filter_string)) {
			filter_string = JSON.stringify(filter_string);
		}
		query_parts.push(`filter=${encodeURIComponent(filter_string)}`);
		if (options.filter_minimum_should_match) {
			query_parts.push(`filter_minimum_should_match=${options.filter_minimum_should_match}`);
		}

	}

	if (options.order) {
		query_parts.push(`order=${encodeURIComponent(options.order)}`);
	}

	if (options.orderby) {
		query_parts.push(`orderby=${encodeURIComponent(options.orderby)}`);
	}

	if (options.after) {
		// if here, we received next page token from server in prior request
		query_parts.push(`after=${encodeURIComponent(JSON.stringify(options.after))}`);
	}

	if (query_parts.length === 0) {
		return('');
	}

	return(`?${query_parts.join('&')}`);
}

if (_testing) {
	_testing.list_generate_query_from_options = function() {
		const checks = [
			{
				options: {
					fields: ['a', 'b']
				},
				output: '?fields=a,b'
			},
			{
				options: {
					count: 10
				},
				output: '?count=10'
			},
			{
				options: {
					filter: 'metadata.toplevel=true'
				},
				output: '?filter=metadata.toplevel%3Dtrue'
			},
			{
				options: {
					filter: ['metadata.toplevel=true']
				},
				output: '?filter=%5B%22metadata.toplevel%3Dtrue%22%5D'
			},
			{
				options: {
					filter: undefined,
					count: undefined,
					fields: []
				},
				output: ''
			},
			{
				options: {
					fields: ['a', 'b'],
					count: 10,
					filter: 'metadata.toplevel=true'
				}
			},
			{
				options: {
					order: 'asc',
					orderby: 'date',
					filter: 'metadata.toplevel=true'
				}
			}
		];

		for (const check of checks) {
			if (check.output !== undefined) {
				const check_output = list_generate_query_from_options(check.options);

				/* istanbul ignore if */
				if (check_output !== check.output) {
					throw(new Error(`Expected to get ${check.output} but got ${check_output} for options ${JSON.stringify(check.options)}`));
				}
			} else {
				const check_query_string = list_generate_query_from_options({
					...check.options,
					_url_encode: false
				});

				/*
				 * Convery the query string into what we would normally get from
				 * the API as the query context.
				 */
				const check_query = (function() {
					const clean = check_query_string.slice(1);
					const parts = clean.split('&');
					const retval = {};
					for (const part of parts) {
						const part_info = part.split('=');
						const key = part_info[0];
						const value = decodeURIComponent(part_info[1]);

						retval[key] = value;
					}

					return(retval);
				})();

				const check_options = list_generate_options_from_query(check_query);

				kaialpha.lib.testing_utils.assert.object_equals(check.options, check_options);
			}
		}

		return(true);
	}
}

/**
 * TODO Refactor - This function is now moved to modules/user.ts, use from there for future
 * Expand user ids of roles and groups into a list
 * @param {string[]} list_to_expand - list of users ids, roles, groups etc
 * @param {Partial<KaiAlphaCMSItemPermissions>} [item_permissions] This is optional only if user_id_list contains only bare user_ids or well known system ids
 * @param {string[]} [user_ids_to_exclude] - Used to maintain single list of all excluded users during recursion. List of user ids to exclude can be provided initially if desired.
 * @returns {string[]} List of user ids
 */
function _verify_permissions_expand_user_id_list(list_to_expand, item_permissions = {}, user_ids_to_exclude = []) {
	list_to_expand = list_to_expand || [];
	const user_id_list_expanded = [];

	for (let item of list_to_expand) {
		/**
		 * Some legacy roles like _save_workflow_set_ui_action_review
		 * contain stringified permission object as a backup for original permissions.
		 * They should not be used in acl directly, hence we dont need expand those roles.
		 */
		if (!item || typeof item !== 'string' || kaialpha.lib.object_utils.is_json_object(item)) {
			continue;
		}

		const is_negative_permission = item[0] === '!';
		if (is_negative_permission) {
			item = item.slice(1);
		}

		const add_to_list = (...user_ids) => {
			if (is_negative_permission) {
				user_ids_to_exclude.push(...user_ids);
			} else {
				user_id_list_expanded.push(...user_ids);
			}
		}

		// system users will also have '@' prefix similar to roles and groups, so check this first before checking `is_single_user_id`
		const is_wellknown_sid = [system_user_id, system_everyone_id, system_nobody_id].includes(item);
		if (is_wellknown_sid) {
			add_to_list(item);
			continue;
		}

		/**
		 * if here, this is not a system user
		 * so if there is '@' in the user_id, it is not a single user id
		 * as user ids are just GUIDs
		 */
		const is_single_user_id = !item.includes('@');
		if (is_single_user_id) {
			add_to_list(item);
			continue;
		}

		// if here, this is a role, user group or some other known ACL type
		const role_or_group = item;

		const type = role_or_group.split(':')[0].slice(1);

		switch (type) {
			case 'owners':
				add_to_list(...item_permissions.owners);
				break;
			case 'role':
				if (!item_permissions.roles) {
					break;
				}

				{
					const role_name = item.split(':').slice(1).join(':');
					const role_users = item_permissions.roles[role_name];
					const sub_user_id_list = _verify_permissions_expand_user_id_list(role_users, item_permissions, user_ids_to_exclude);
					add_to_list(...sub_user_id_list);
				}
				break;
			case 'group':
				/** XXX:TODO **/
				{
					// eslint-disable-next-line
					const group_name = item.split(':').slice(1).join(':');
					kaialpha.log.debug(`[WARNING] Groups are not yet supported when processing ${item}`)
				}
				break;
			default:
				throw(new Error(`Unknown ACL Type: ${type} in ${item}`));
		}

	}

	// remove any excluded users that were added to this list prior to processing negative permissions
	const user_id_list_excluded = user_id_list_expanded.filter(user_id => !user_ids_to_exclude.includes(user_id));

	// remove any duplicates
	return([...(new Set([...user_id_list_excluded]))]);
}

/*
 * Compare two given levels to see if the former is included in the latter
 *
 * Return value: boolean
 */
function _verify_permissions_compare_level(level, want) {
	if (level === want) {
		return(true);
	}

	if (level === 'write') {
		return(true);
	}

	return(false);
}

if (_testing) {
	_testing._verify_permissions_compare_level = function() {
		const table = [
			{l: 'write', w: 'write', r: true},
			{l: 'write', w: 'read', r: true},
			{l: 'read', w: 'write', r: false},
			{l: 'write:comment', w: 'write', r: false},
			{l: 'write', w: 'write:comment', r: true},
			{l: 'write:comment', w: 'write:comment', r: true},
			{l: 'write:body', w: 'write:comment', r: false},
		]

		for (const item of table) {
			const r = _verify_permissions_compare_level(item.l, item.w);

			/* istanbul ignore if */
			if (r !== item.r) {
				throw(new Error(`When comparing level="${item.l}" and want="${item.w}" got ${r} instead of ${item.r}`));
			}
		}

		return(true);
	}

	_testing._verify_permissions_expand_user_id_list_without_users = function () {
		// @ts-ignore
		const result = _verify_permissions_expand_user_id_list();

		/* istanbul ignore if */
		if (result.length > 0) {
			throw new Error("Result without user list should not have any data");
		}

		return true;
	}

	_testing._verify_permissions_expand_user_id_list_system_user = function () {

		const result = _verify_permissions_expand_user_id_list(['@system']);

		/* istanbul ignore if */
		if (result[0] !== '@system') {
			throw new Error("Should have @system but found" + result[0]);
		}
		return true;
	}

	_testing._verify_permissions_expand_user_id_list_all_user = function () {

		const result = _verify_permissions_expand_user_id_list(['@all']);

		/* istanbul ignore if */
		if (result[0] !== '@all') {
			throw new Error("Should have @all but found" + result[0]);
		}
		return true;
	}

	_testing._verify_permissions_expand_user_id_list_without_type = function () {

		try {
			_verify_permissions_expand_user_id_list(["@something"]);
		} catch (err) {
			return true;
		}

		/* istanbul ignore next */
		throw new Error("Should have thrown not found error, but passed the function");
	}

	_testing._verify_permissions_expand_user_id_list_with_role = function () {
		const expected = ['abc', 'xyz'];
		const actual = _verify_permissions_expand_user_id_list(["@role:dummy"], { 'roles': { dummy: expected } });
		kaialpha.lib.testing_utils.assert.object_equals(actual, expected, 'Should have items from role');

		return true;
	}

	_testing._verify_permissions_expand_user_id_list_with_owners = function () {
		const expected = ['abc', 'xyz'];
		const actual = _verify_permissions_expand_user_id_list(["@owners"], { owners: expected });
		kaialpha.lib.testing_utils.assert.object_equals(actual, expected, 'Should have items from owners');

		return true;
	}

	_testing._verify_permissions_expand_user_id_list_with_negative_permissions = function () {
		const expected = ['abc'];

		let actual = _verify_permissions_expand_user_id_list(["@owners", '!@role:dummy'], { owners: ['abc', 'xyz'], roles: { dummy: ['xyz', '123'] } });
		kaialpha.lib.testing_utils.assert.object_equals(actual, expected, 'Should not have items from !@role:dummy');

		actual = _verify_permissions_expand_user_id_list(["@owners", '!xyz'], { owners: ['abc', 'xyz'] });
		kaialpha.lib.testing_utils.assert.object_equals(actual, expected, 'Should exclude items prefixed with "!"');
		return true;
	}

	_testing._verify_permissions_expand_user_id_list_without_role = function () {
		const result = _verify_permissions_expand_user_id_list(["@role"], { 'roles': undefined });
		/* istanbul ignore if*/
		if (result.length > 0) {
			throw new Error(`Should not have any items in result as roles are undefined but found ${result}`);
		}
		return true;
	}

	_testing._verify_permissions_expand_user_id_list_without_user_id_list = function () {
		const result = _verify_permissions_expand_user_id_list([undefined], { 'roles': undefined });
		/* istanbul ignore if*/
		if (result.length > 0) {
			throw new Error(`Should not have any items in result as roles are undefined but found ${result}`);
		}
		return true;
	}

	_testing._verify_permissions_expand_user_id_list_group_type = function () {
		// ts ignore can be removed once groups are added to permissions schema
		// @ts-ignore
		const result = _verify_permissions_expand_user_id_list(['@group'], { 'group': 'csk' });
		/* istanbul ignore if*/
		if (result.length > 0) {
			throw new Error(`Should not have any items in result as groups are not implemented but found ${result}`);
		}
		return true;
	}
}

function verify_permissions_cache_set(object, type, id, value) {
	if (!object) {
		return;
	}

	const item_key = ['type', type, 'id', id].join('|');

	object[item_key] = value;
}

function verify_permissions_cache_get(object, type, id) {
	if (!object) {
		return(undefined);
	}

	const item_key = ['type', type, 'id', id].join('|');
	const retval = object[item_key];

	let target = 'hit';
	if (retval === undefined) {
		target = 'miss';
	}
	object[`stats_cache_${target}`] = (object[`stats_cache_${target}`] || 0) + 1;

	return(retval);
}

/**
 * Given a base permissions object, fetch all the inherited permissions
 * recursively and return a single merged permissions object.
 * Example format for document permissions:
	```
	"permissions": {
		"owners": ["user1","user2"],
		"roles": {
			"reviewers": ["user1","user2"],
			"readers": ["user1","user2"],
			"authors": ["user1","user2"],
			"approvers": ["user1","user2"],
		},
		"acl": {
			"read": [
				"@role:readers",
				"@role:reviewers"
			],
			"write": [
				"@role:authors"
			],
			"comments:write": [
				"@role:reviewers"
			]
		}
	}
	```
 * @param {KaiAlphaCMSItemPermissions} permissions
 * @param {*} options
 * @returns {Promise<KaiAlphaCMSItemPermissions>}
 */
async function get_merged_inherited_permissions(permissions, options = {}) {
	options = {
		get_item_any_type: kaialpha.lib.general_utils.get_item_any_type,
		_state: {
			seen: {}
		},
		...options
	};

	if (!permissions) {
		return(permissions);
	}

	/**
	 * some downstream methods mutate the permissions object returned from this method
	 * so always clone the permissions object here to avoid any side effects from mutations
	 */
	permissions = kaialpha.lib.object_utils.copy_object(permissions);
	const inherit_from = permissions.inherit_from || [];

	if (inherit_from && !Array.isArray(inherit_from)) {
		// this should never happen
		kaialpha.lib.debug.log('acl.debug', `Incorrect inherit_from format. Expected array, given: `, inherit_from);
		return(permissions);
	}

	if (inherit_from.length === 0) {
		// if here, nothing was inherited or the inherited permissions might have already been expanded.
		kaialpha.lib.debug.log('acl.debug', `No inherited permissions. Returning current permissions`);
		return(permissions);
	}

	for (const item of inherit_from) {
		const type = item.type;
		const id = item.id;

		if (!type || !id) {
			kaialpha.lib.debug.log('acl.debug', `Invalid inherit_from entry, type and id are missing: ${JSON.stringify(item)}`);
			continue;
		}

		/*
		 * Avoid loops by avoiding reprocessing the same item
		 */
		const item_key = ['type', type, 'id', id].join('|');
		if (options._state.seen[item_key] === true) {
			continue;
		}

		let inherit_item;
		try {
			inherit_item = verify_permissions_cache_get(options.fetch_cache, type, id);

			if (!inherit_item) {
				inherit_item = await options.get_item_any_type(system_user_id, type, id, 'HEAD', {
					fields: ['permissions']
				});

				verify_permissions_cache_set(options.fetch_cache, type, id, inherit_item);
			}

		} catch (verify_permissions_error) {

			kaialpha.lib.debug.log('acl.debug', 'Failed to fetch', type, 'id', id, 'version HEAD -- skipping merging permissions of it:',
				JSON.stringify(verify_permissions_error));
			continue;

		} finally {
			// mark this as seen to avoid loops
			options._state.seen[item_key] = true;
		}

		if (!inherit_item) {
			// if here, get_item_any_type returned empty result
			kaialpha.lib.debug.log('acl.debug', 'inherit_item not present', type, 'id', id, 'version HEAD -- skipping merging permissions of it.');
			continue;
		}

		const inherited_permissions = await get_merged_inherited_permissions(inherit_item.permissions, options);
		if (!inherited_permissions) {
			kaialpha.lib.debug.log('acl.debug', 'Permissions not present', type, 'id', id, 'version HEAD -- skipping merging permissions of it.');
			continue;
		}

		// merge inherited permissions with current

		// merge owners
		permissions.owners = [...(new Set([...permissions.owners, ...inherited_permissions.owners]))];

		// merge roles and acl
		for (const part_type of ['roles', 'acl']) {
			const current_permissions_part = permissions[part_type] || {};
			const inherited_permissions_part = inherited_permissions[part_type] || {};

			const keys = new Set([...Object.keys(current_permissions_part), ...Object.keys(inherited_permissions_part)]);

			for (const key of keys) {
				const current_permissions_subpart = current_permissions_part[key] || [];
				const inherited_item_subpart = inherited_permissions_part[key] || [];

				const subpart_merged = [...(new Set([...current_permissions_subpart, ...inherited_item_subpart]))];

				if (!permissions[part_type]) {
					permissions[part_type] = {};
				}

				permissions[part_type][key] = subpart_merged;
			}
		}
	}

	// remove inherit link to indicate this is already expanded
	delete permissions.inherit_from;

	return(permissions);
}

async function canonicalize_permissions(permissions, options = {}) {
	options = {
		get_item_any_type: kaialpha.lib.general_utils.get_item_any_type,
		_state: {
			seen: {}
		},
		...options
	};

	if (!permissions) {
		return(permissions);
	}

	/**
	 * Some roles would only be partially filled if we merge canonicalized permissions directly.
	 * So first get the single merged permissions object and then canonicalize.
	 */
	const merged_permissions = await get_merged_inherited_permissions(permissions, options);

	// canonicalize owners as well incase owners also include user groups
	merged_permissions.owners = _verify_permissions_expand_user_id_list(merged_permissions.owners, merged_permissions);

	// acl will be undefined no users are set in it.
	merged_permissions.acl = merged_permissions.acl || {};
	const acl = merged_permissions.acl
	const acl_types = Object.keys(acl);
	for (const acl_type of acl_types) {
		acl[acl_type] = _verify_permissions_expand_user_id_list(acl[acl_type], merged_permissions);
	}

	delete merged_permissions['roles'];
	delete merged_permissions['inherit_from'];

	return(merged_permissions);
}

/*
 * Canonicalize a role (XXX:TODO)
 */
async function canonicalize_roles(permissions, options = {}) {
	options = {
		get_item_any_type: kaialpha.lib.general_utils.get_item_any_type,
		_state: {
			seen: {}
		},
		...options
	};

	const merged_permissions = await get_merged_inherited_permissions(permissions, options);

	const roles = merged_permissions.roles || {};
	const role_types = Object.keys(roles);

	const expanded_roles = {};
	for (const role_type of role_types) {

		// skip internal back up permissions
		if (role_type.startsWith('_save_workflow')) {
			continue;
		}

		expanded_roles[role_type] = _verify_permissions_expand_user_id_list(roles[role_type], merged_permissions);
	}

	return(expanded_roles);
}

/**
 * Given a base permissions object, fetch all the inherited permissions
 * recursively and return permissions object with all roles, acl, owners lists expanded.
 * @param {KaiAlphaCMSItemPermissions} base_permissions
 * @param {*} options
 * @returns {Promise<KaiAlphaCMSItemPermissions>} Expanded permissions with users list
 */
async function canonicalize_permissions_and_roles(base_permissions, options = {}) {

	base_permissions = kaialpha.lib.object_utils.copy_object(base_permissions);

	options = {
		get_item_any_type: kaialpha.lib.general_utils.get_item_any_type,
		_state: {
			seen: {}
		},
		...options
	};

	// get the merged inherited permissions first and expand them later to avoid redundant db calls
	const merged_permissions = await get_merged_inherited_permissions(base_permissions, options);
	if (!merged_permissions) {
		return({
			owners: [],
			roles: {},
			acl: {},
		});
	}

	/**
	 * These functions dont really call db to fetch inherited permissions since
	 * `inherited_from` link is deleted by get_merged_inherited_permissions.
	 */
	const expanded_roles = await canonicalize_roles(merged_permissions, options);
	const expanded_acl = await canonicalize_permissions(merged_permissions, options);

	return({
		...expanded_acl,
		roles: {
			...expanded_roles,
		},
	});
}

/*
 * Given a user and an access list of users & groups, determine if the user is in the list.
 * Note: Either the user or a group the user belongs to count as a match
 */
async function _get_user_id_and_user_groups_as_list( user_id ) {
	// Retrieve all groups associated with the user
	try {
		const cur_user = await kaialpha.lib.user.get_user_info_by_id("", user_id);
		const all = (cur_user && cur_user.groups && cur_user.groups.length > 0) ? cur_user.groups.concat(user_id) : [user_id];
		return all;
	} catch(exc) {
		console.log(`Unable to load the user's groups.`);
		console.log(exc);
		throw exc;
	}
}

/*
 * Determine if a given user ID has the requested level of access
 * based on the permission set of a document
 *
 * There are three levels of access: read, write, owner
 *
 * Owner has all the permissions of "write", plus the ability to change
 * owner and change permissions.
 *
 * Write has all the permissions of "read", plus the ability to create
 * new versions of the document (or, if scoped, that sub-document).
 *
 * Read can read the document, and ALL past versions (or, if scoped
 * a specific sub-tree).
 *
 * Scoped rules look like <scope>:<level>, where unscoped rules look like
 * <level>.
 *
 * Additionally, symbolic roles, which are document-specified aliases for
 * many user or group IDs, can be specified as "roles", and referenced by
 * "@role:<role>" within the "acl" portion of the permissions document.
 *
 * Group IDs may be specified as @group:<group_id>
 *
 * Example permissions document:
 *       {
 *           "owners": ["uid1", "gid1"],
 *           "roles": {
 *               "friends": ["uid3"],
 *               "reviewer": ["uid2"],
 *               "author": ["@group:gid"]
 *           },
 *           "acl" {
 *               "read": ["@role:friends"],
 *               "write": ["@role:author"],
 *               "comments:write": ["@role:reviewer"]
 *           }
 *       }
 *
 * Return value: boolean
 */
async function _verify_permissions(user_id, requested_permissions, item_permissions, options = {}) {
	/*
	 * By default, deny access -- but if ACL enforcement is disabled, then
	 * change this default to allow.
	 */
	let default_disposition = false;
	const user_id_and_groups = await _get_user_id_and_user_groups_as_list(user_id);
	if (kaialpha.configuration.disable_acl_enforcement === true) {
		default_disposition = true;
	}
	options = Object.assign({
		default_disposition
	}, options);

	kaialpha.lib.debug.log('acl.debug', 'Checking to see if User ID', user_id, 'has permission', requested_permissions, 'against permissions list:', item_permissions);

	/*
	 * The system user always has access to everything.
	 */
	if (user_id === system_user_id) {
		return(true);
	}

	/*
	 * Expand item_permissions by expansion from inherited ACLs
	 */
	item_permissions = await canonicalize_permissions(item_permissions, options);

	/*
	 * Validate that the requested permissions are valid.
	 *
	 * They must be one of read, write, owner, or scoped read/write.
	 */
	/** XXX:TODO **/

	/*
	 * If the user belongs to a group of Administrators, override the ACL
	 * and allow full control
	 */
	/** XXX:TODO **/

	/*
	 * If the permissions are missing, nobody (except system) has any
	 * access.
	 */
	if (!item_permissions) {
		if (options.default_disposition) {
			kaialpha.lib.debug.log('acl.info', `ACL Enforcement is disabled, but would have rejected ${user_id} ${requested_permissions} access (no permissions document)`);
		}

		return(options.default_disposition);
	}

	/*
	 * The owner of a document may make any changes.
	 */
	if (item_permissions.owners instanceof Array) {
		const owners = item_permissions.owners;
		for (const owner of owners) {
			if (user_id_and_groups.includes(owner)) {
				return(true);
			}
		}
	}

	/*
	 * No other users may make ACL changes, only read/write or scoped read/write
	 */
	if (requested_permissions === 'owner') {
		if (options.default_disposition) {
			kaialpha.lib.debug.log('acl.info', `ACL Enforcement is disabled, but would have rejected ${user_id} ${requested_permissions} access based on ${JSON.stringify(item_permissions)} (not owner)`);
		}

		return(options.default_disposition);
	}

	/*
	 * Process the ACL
	 */
	/**
	 ** If the ACL is empty, return the default disposition immediately
	 **/
	if (!item_permissions.acl) {
		if (options.default_disposition) {
			kaialpha.lib.debug.log('acl.info', `ACL Enforcement is disabled, but would have rejected ${user_id} ${requested_permissions} access based on ${JSON.stringify(item_permissions)} (no ACL)`);
		}

		return(options.default_disposition);
	}

	/**
	 ** Process all the relevant ACL entries
	 **/
	for (const level in item_permissions.acl) {
		/***
		 *** If this ACL level won't grant the requested
		 *** permissions then we do not need to process it.
		 ***/
		if (!_verify_permissions_compare_level(level, requested_permissions)) {
			continue;
		}

		/***
		 *** Now that we know this rule will grant the requested
		 *** access, determine if the user is eligible for it.
		 ***
		 *** Get the list of users and expand it.
		 ***/
		const user_id_list = item_permissions.acl[level];
		for(const curEntry of user_id_and_groups) {
			if (user_id_list.includes(curEntry)) {
				return (true);
			}
		}

		/***
		 *** If the set of users includes "Everyone", then any logged in
		 *** user will match.
		 ***/
		if (user_id_list.includes(system_everyone_id)) {
			return(true);
		}
	}

	if (options.default_disposition) {
		kaialpha.lib.debug.log('acl.info', `ACL Enforcement is disabled, but would have rejected ${user_id} ${requested_permissions} access based on ${JSON.stringify(item_permissions)} (not allowed)`);
	}

	return(options.default_disposition);
}

async function verify_permissions(user_id, requested_permissions, item_permissions, options = {}) {
	let retval = false;

	try {
		retval = await _verify_permissions(user_id, requested_permissions, item_permissions, options);
	} catch (verify_perms_error) {
		retval = false;

		kaialpha.lib.debug.log('cms.error', 'Error while validating permissions', item_permissions, ':', verify_perms_error);
	}

	return(retval);
}

if (_testing) {
	const uuid = require('uuid');

	_testing.verify_permissions = async function() {
		const table = [
			{u: 'user1', w: 'write', d: {
				owners: ['user1']
			}, r: true},
			{u: 'user1', w: 'read', d: {
				owners: ['user1']
			}, r: true},
			{u: 'user2', w: 'write', d: {
				owners: ['user1']
			}, r: false},
			{u: 'user2', w: 'write', d: {
				owners: ['user1'],
				acl: {
					"write": ['user2']
				}
			}, r: true},
			{u: 'user2', w: 'write', d: {
				owners: ['user1'],
				roles: {
					authors: ['user2']
				},
				acl: {
					"write": ['@role:authors']
				}
			}, r: true},
			{u: 'user2', w: 'read', d: {
				owners: ['user1'],
				roles: {
					authors: ['user2']
				},
				acl: {
					"write": ['@role:authors']
				}
			}, r: true},
			{u: 'user3', w: 'read', d: {
				owners: ['user1'],
				roles: {
					authors: ['user2']
				},
				acl: {
					"write": ['@role:authors']
				}
			}, r: false},
			{u: 'user3', w: 'read', d: {
				owners: ['user1'],
				roles: {
					authors: ['user2'],
					reviewers: ['user3']
				},
				acl: {
					"write": ['@role:authors'],
					"write:comments": ['@role:reviewers'],
					"read": ['@role:reviewers']
				}
			}, r: true},
			{u: 'user4', w: 'read', d: {
				owners: ['demo-user-id-1'],
				acl: {
					read: ['@all']
				}
			}, r: true},
			{u: '@system', w: 'null', d: {
				owners: ['demo-user-id-1'],
				acl: {
					read: ['@all']
				}
			}, r: true},
			{ u: 'user', w: 'null', d: undefined, r: false },
			{u: 'user-5', w: 'owner', d: {
				owners: ['demo-user-id-1'],
				acl: {
					read: ['@all']
				}
			}, r: false},
		]

		for (const item of table) {
			const r = await verify_permissions(item.u, item.w, item.d, { default_disposition: false });

			/* istanbul ignore if */
			if (r !== item.r) {
				throw(new Error(`When validating that user "${item.u}" can get "${item.w}" access to document with ACL ${JSON.stringify(item.d)}, got ${r} instead of ${item.r}`));
			}
		}

		return(true);
	}

	_testing.verify_permissions_with_default_disposition = async function () {
		const permissions = {
			owners: ['user-6'], acl: {
				read: ['@all']
			}
		};
		const result = await verify_permissions('user-7', 'owner', permissions, { 'default_disposition': true });

		/* istanbul ignore if */
		if (!result) {
			throw new Error(`While validating with default_disposition true it verification returned ${result} but needed true`);
		}
		return true;
	};

	_testing.verify_permissions_with_default_disposition_2 = async function () {
		const result = await verify_permissions('user-6', 'owner', undefined, { 'default_disposition': true });

		/* istanbul ignore if */
		if (!result) {
			throw new Error(`While validating with default_disposition true it verification returned ${result} but needed true`);
		}
		return true;
	};

	_testing.verify_permissions_with_default_disposition_3 = async function () {
		const permissions = {
			owners: ['user-6']
		};

		const result = await verify_permissions('user-7', 'read', permissions, { 'default_disposition': true });

		/* istanbul ignore if */
		if (!result) {
			throw new Error(`While validating with default_disposition true it verification returned ${result} but needed true`);
		}
		return true;
	};

	_testing.verify_permissions_expand = async function () {
		const permissions = {
			'type_document_id_test1': {
				owners: ['user6'],
				inherit_from: [
					{
						type: 'document',
						id: 'test2'
					}
				]
			},
			'type_document_id_test2': {
				owners: ['user7']
			},
			'type_document_id_test3': {
				owners: ['user1'],
				roles: {
					authors: ['user2'],
					reviewers: ['user10', 'user11'],
					testing: ['user12']
				},
				acl: {
					"write": ['@role:authors'],
					"read": ['@role:testing']
				}
			},
			'type_document_id_test4': {
				owners: ['user5'],
				roles: {
					authors: ['user3'],
					reviewers: ['user10']
				},
				acl: {
					"write": ['@role:authors'],
					"read": ['@role:reviewers']
				},
				inherit_from: [
					{id: 'test3', type: 'document'},
					{id: 'test2', type: 'document'}
				]
			},
			'type_document_id_test4b': {
				owners: ['user5'],
				roles: {
					authors: ['user3', '@role:mergetest'],
					mergetest: ['user11']
				},
				acl: {
					"write": ['@role:authors'],
				}
			},
			'type_document_id_test5': {
				owners: ['user20'],
				roles: {
					authors: ['user3', '@role:testing'],
					reviewers: ['user10'],
					testing: ['user21']
				},
				acl: {
					"write": ['@role:authors'],
					"read": ['@role:reviewers']
				},
				inherit_from: [
					{id: 'test4', type: 'document'},
					{id: 'test2', type: 'document'},
				]
			},
			'type_document_id_test6': {
				owners: ['user60'],
				roles: {
					mergetest: ['user61']
				},
				acl: {
					"read": ['@role:mergetest']
				},
				inherit_from: [
					{id: 'test4b', type: 'document'}
				]
			}
		};

		const checks = [
			{ id: 'type_document_id_test1', w: 'owner', u: 'user7', r: true },
			{ id: 'type_document_id_test2', w: 'owner', u: 'user7', r: true },
			{ id: 'type_document_id_test1', w: 'owner', u: 'user6', r: true },
			{ id: 'type_document_id_test2', w: 'owner', u: 'user6', r: false },
			{ id: 'type_document_id_test3', w: 'owner', u: 'user1', r: true },
			{ id: 'type_document_id_test3', w: 'owner', u: 'user2', r: false },
			{ id: 'type_document_id_test3', w: 'write', u: 'user2', r: true },
			{ id: 'type_document_id_test3', w: 'write', u: 'user3', r: false },
			{ id: 'type_document_id_test3', w: 'read', u: 'user12', r: true },
			{ id: 'type_document_id_test3', w: 'read', u: 'user10', r: false },
			{ id: 'type_document_id_test4', w: 'owner', u: 'user1', r: true },
			{ id: 'type_document_id_test4', w: 'owner', u: 'user5', r: true },
			{ id: 'type_document_id_test4', w: 'owner', u: 'user2', r: false },
			{ id: 'type_document_id_test4', w: 'owner', u: 'user7', r: true },
			{ id: 'type_document_id_test4', w: 'write', u: 'user2', r: true },
			{ id: 'type_document_id_test4', w: 'write', u: 'user3', r: true },
			{ id: 'type_document_id_test4', w: 'write', u: 'user4', r: false },
			{ id: 'type_document_id_test4', w: 'write', u: 'user4', r: false },
			{ id: 'type_document_id_test4', w: 'read', u: 'user12', r: true },
			{ id: 'type_document_id_test4', w: 'read', u: 'user10', r: true },
			{ id: 'type_document_id_test5', w: 'owner', u: 'user20', r: true },
			{ id: 'type_document_id_test5', w: 'owner', u: 'user1', r: true },
			{ id: 'type_document_id_test5', w: 'write', u: 'user21', r: true },
			{ id: 'type_document_id_test5', w: 'write', u: 'user12', r: true },
			{ id: 'type_document_id_test5', w: 'read', u: 'user12', r: true },
			{ id: 'type_document_id_test6', w: 'owner', u: 'user60', r: true },
			{ id: 'type_document_id_test6', w: 'owner', u: 'user5', r: true },
			{ id: 'type_document_id_test6', w: 'write', u: 'user61', r: true },
			{ id: 'type_document_id_test6', w: 'read', u: 'user61', r: true },
			{ id: 'type_document_id_test6', w: 'write', u: 'user3', r: true },
			{ id: 'type_document_id_test6', w: 'write', u: 'user11', r: true },
		];

		for (const check of checks) {
			const permissions_document = permissions[check.id];
			const result = await verify_permissions(check.u, check.w, permissions_document, {
				get_item_any_type: async function(user_id, type, id, version) {
					if (version !== 'HEAD' && version !== undefined) {
						throw(new Error('We only support latest version'));
					}

					const index = ['type', type, 'id', id].join('_');
					const object = {
						name: 'dummy',
						id: id,
						version: uuid.v4(),
						permissions: permissions[index]
					};
					return(object);
				},
				default_disposition: false
			});

			/* istanbul ignore if */
			if (result !== check.r) {
				throw new Error(`While validating with expansion verification of ${JSON.stringify(check)} returned ${result} but needed ${check.r}`);
			}
		}

		return true;
	};
}

function verify_fit_dynamo_cache(record) {
	const record_dynamodb = kaialpha.lib.aws_utils.object_to_dynamodb(record);

	const record_buffer = Buffer.from(JSON.stringify(record_dynamodb), 'utf-8');
	if (record_buffer.length > MAX_INLINE_CACHE_LENGTH) {
		return(false);
	}

	return(true);
}

const _to_export_auto = {
	list_generate_options_query_help,
	list_generate_options_from_query,
	list_generate_query_from_options,
	canonicalize_permissions,
	canonicalize_roles,
	canonicalize_permissions_and_roles,
	expand_user_id_list: _verify_permissions_expand_user_id_list,
	verify_permissions,
	verify_permissions_cache_set,
	verify_permissions_cache_get,
	verify_fit_dynamo_cache,
	special_uids: {
		system_user_id,
		system_everyone_id,
		system_nobody_id
	},
	_testing
}
export default _to_export_auto;
