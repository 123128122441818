/*
 * DO NOT EDIT THIS FILE
 *
 * This file has been automatically generated and any changes
 * made here will NOT be preserved
 *
 * This file was generated from: /Users/antonyjc/Development/clients/kaialpha-poc/src/kaialpha/client/workflow.mjs
 *
 * DO NOT EDIT THIS FILE
 */
// eslint-disable-next-line
import kaialpha from '../lib/kaialpha';
import api from './api';
import user from './user';
import cache_utils from '../lib/utils/cache_utils';
const cache_workflow_short_ttl = 30 * 1000;
const cache_workflow_info_ttl = 2 * 60 * 1000;
const cache_workflow_perms_ttl = 10 * 60 * 1000;

export async function get_user_workflows(user_id, options = {}) {
	const query = kaialpha.lib.versions_utils.list_generate_query_from_options(options);
	const request = `workflow${query}`;

	const response = await cache_utils.cache_promise('cache_workflow_short', `list_${request}`, async function() {
		return(await api.call('GET', request));
	}, {
		cache_expires: cache_workflow_info_ttl
	});

	const workflow_info = response;

	return(workflow_info);
}

export async function get_user_workflow(user_id, id, version = undefined) {
	if (version === 'HEAD') {
		version = undefined;
	}

	const key = ['fulldata', 'id', id, 'version', version].join('_');

	let retval;
	if (version) {
		retval = await cache_utils.cache_promise('cache_workflow', key, async function() {
			return(await api.call('GET', `workflow/${id}/versions/${version}`));
		});
	} else {
		retval = await cache_utils.cache_promise('cache_workflow_short', key, async function() {
			return(await api.call('GET', `workflow/${id}`));
		}, {
			cache_expires: cache_workflow_short_ttl
		});
	}

	return(retval);
}

export async function delete_workflow(user_id, workflow_id) {
	const retval = await api.call('DELETE', `workflow/${workflow_id}`);

	await cache_utils.clear('cache_workflow_short');

	return(retval);
}

export async function update_user_workflow(user_id, workflow_id, workflow_version, name, actions, permissions, default_info) {
	/* XXX:TODO: Change this to PATCH */

	const old_values = await get_user_workflow(user_id, workflow_id, workflow_version);

	const payload = {
		...old_values,
		id: workflow_id,
		version: workflow_version,
	};

	if (name) {
		payload.name = name;
	}

	if (actions) {
		payload.actions = actions;
	}

	if (permissions) {
		payload.permissions = permissions;
	}

	if (default_info) {
		payload.default = default_info;
	}

	const retval = await api.call('PUT', `workflow/${workflow_id}`, payload);

	await cache_utils.clear('cache_workflow_short');

	return(retval);
}

export async function new_user_workflow(user_id, name, actions, default_for) {
	if (name === "null") {
		name = "Undefined";
	}

	const payload = {
		name,
		actions,
		default: default_for,
		permissions: {
			owners: [await user.get_user_id()],
			acl: {
				read: ['@all']
			}
		}
	}

	const retval = await api.call('POST', 'workflow', payload);

	await cache_utils.clear('cache_workflow_short');

	return(retval);
}

export async function get_user_workflow_canonical_permissions(user_id, workflow_id) {
	const key = ['permissions', 'id', workflow_id];

	const retval = await cache_utils.cache_promise('cache_workflow_short', key, async function() {
		return(await api.call('GET', `workflow/${workflow_id}/permissions`));
	}, {
		cache_expires: cache_workflow_perms_ttl
	});

	return(retval);
}

const _to_export = {
	get_user_workflows,
	get_user_workflow,
	delete_workflow,
	update_user_workflow,
	new_user_workflow,
	get_user_workflow_canonical_permissions
}
export default _to_export;
