/*
 * DO NOT EDIT THIS FILE
 *
 * This file has been automatically generated and any changes
 * made here will NOT be preserved
 *
 * This file was generated from: /Users/antonyjc/Development/clients/kaialpha-poc/src/kaialpha/lib/promise_utils.js
 *
 * DO NOT EDIT THIS FILE
 */
// eslint-disable-next-line
import kaialpha from '../kaialpha';
const PROMISE_ID_KEY = '@promise_b787d89f-32f9-447f-a637-0805d5a55d37';
const PROMISE_DISPATCH_MAGIC = '@promise_call_89730be9-0054-4878-8ae9-1a29b383dad8';

async function wait_for_promise(fetch_result) {
	const value = await fetch_result.json();
	if (value[PROMISE_ID_KEY] === undefined) {
		return({
			ok: fetch_result.ok,
			status: fetch_result.status,
			headers: fetch_result.headers,
			json: async function() {
				return(value);
			}
		});
	}

	const promise_id = value[PROMISE_ID_KEY].id;

	for (let retry = 0; retry < 1000; retry++) {
		if (retry !== 0) {
			let backoff = 250 + ((retry - 1) * 100);
			if (backoff > 1000) {
				backoff = 1000;
			}

			await kaialpha.lib.general_utils.async_sleep(backoff);
		}

		const result_raw = await kaialpha.lib.promise.get_user_promise(null, promise_id);
		if (result_raw[PROMISE_ID_KEY] === undefined) {
			return({
				ok: true,
				status: 200,
				headers: {
					get: function(header) {
						switch (header.toLowerCase()) {
							case 'content-type':
								if (result_raw instanceof Object) {
									return('application/json');
								} else {
									return('text/html');
								}
							default:
								throw(new Error(`Fetching header ${header} not supported`));
						}
					}
				},
				json: async function() { return(result_raw); },
				text: async function() { return(result_raw); }
			});
		}

		const result = result_raw[PROMISE_ID_KEY];

		let settled = false;
		if (result.settled === true) {
			settled = true;
		}

		if (!settled) {
			continue;
		}

		const fetch_result = await fetch(result.link);

		/*
		 * If the promise was successfully resolved,
		 * transfer the result to fetching the
		 * resource from the specified link
		 */
		if (result.status === 'resolved') {
			return(fetch_result);
		}

		/*
		 * Otherwise, proxy the error
		 */
		return({
			ok: false,
			status: result.http_code,
			headers: {
				get: function(header) {
					switch (header.toLowerCase()) {
						case 'content-type':
							return('application/json');
						default:
							throw(new Error(`Fetching header ${header} not supported`));
					}
				}
			},
			json: async function() {
				const json = await fetch_result.json();
				return(json);
			}
		});
	}

	return({
		ok: false,
		status: 504,
		headers: {
			get: function(header) {
				switch (header.toLowerCase()) {
					case 'content-type':
						return('application/json');
					default:
						throw(new Error(`Fetching header ${header} not supported`));
				}
			}
		},
		json: async function() { return({error: 'promise never settled'}); }
	});
}

const _to_export_auto = {
	constants: {
		PROMISE_ID_KEY,
		PROMISE_DISPATCH_MAGIC
	},
	wait_for_promise
};
export default _to_export_auto;
